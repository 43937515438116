import { Heading, Tag } from "@flixbus/honeycomb-react";

const EmptyLines = ({ lines }) => {
    return (
        <>
            {lines?.length > 0 &&
                <Heading extraClasses='flix-space-2-top' key={1} size={2}>Empty Lines</Heading>
            }

            {
                lines?.map((element, index) => (
                    <Tag tagTextColor="white" extraClasses='flix-space-1-top flix-space-1-right danger'>
                        {element}
                    </Tag>
                ))
            }
        </>
    );
}

export default EmptyLines;