import React from 'react';
import './town-buildings.scss';

const House1 = () => (
  <svg className="house-1" width="88" height="67" viewBox="0 0 88 67" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="color-1" d="M40.0692 20.9029L62.7497 1.73364L85.4301 20.9029V66.2164H40.0692V20.9029Z" />
    <path className="color-2" d="M40.0692 20.9029V24.9835L62.7497 5.67188L85.4301 24.6039V20.0963L62.7497 1.73364L40.0692 20.9029Z" />
    <path className="roof-1" d="M85.5725 22.5637L62.7497 3.2995L39.9269 22.5637L38.4085 20.7606L62.7497 0.215332L87.0908 20.7606L85.5725 22.5637Z" />
    <path className="window lights-on" d="M62.7497 20.7606C64.7413 20.7606 66.3558 19.1461 66.3558 17.1545C66.3558 15.1629 64.7413 13.5483 62.7497 13.5483C60.7581 13.5483 59.1436 15.1629 59.1436 17.1545C59.1436 19.1461 60.7581 20.7606 62.7497 20.7606Z" />
    <path className="highlights" d="M62.7497 20.9504C60.662 20.9504 58.9064 19.2422 58.9064 17.107C58.9064 14.9718 60.6145 13.2637 62.7497 13.2637C64.8849 13.2637 66.5931 14.9718 66.5931 17.107C66.5931 19.2422 64.8375 20.9504 62.7497 20.9504ZM62.7497 13.7856C60.8992 13.7856 59.3809 15.304 59.3809 17.1545C59.3809 19.005 60.8992 20.5233 62.7497 20.5233C64.6002 20.5233 66.1186 19.005 66.1186 17.1545C66.1186 15.304 64.6002 13.7856 62.7497 13.7856Z" />
    <path className="color-3" d="M62.7497 53.3579C59.191 53.3579 56.2967 56.2523 56.2967 59.8109V66.2639H69.1552V59.8109C69.2027 56.2523 66.3083 53.3579 62.7497 53.3579Z" />
    <path className="window" d="M58.0523 34.5208H46.1901V46.3829H58.0523V34.5208Z" />
    <path className="highlights" d="M52.3109 34.5208H51.8365V46.3829H52.3109V34.5208Z" />
    <path className="highlights" d="M58.0523 40.2146H46.1901V40.6891H58.0523V40.2146Z" />
    <path className="window" d="M79.7838 34.5208H67.9216V46.3829H79.7838V34.5208Z" />
    <path className="highlights" d="M74.0424 34.5208H73.568V46.3829H74.0424V34.5208Z" />
    <path className="highlights" d="M79.7838 40.2146H67.9216V40.6891H79.7838V40.2146Z" />
    <path className="color-1" d="M40.0692 34.9004H3.96075V66.2165H40.0692V34.9004Z" />
    <path className="roof-2" d="M40.0692 22.7534H6.33321L0.544464 34.9003H40.0692V22.7534Z" />
    <path className="color-2" d="M36.131 48.5181H9.13266V66.2164H36.131V48.5181Z" />
    <path className="color-3" d="M36.131 51.6023H9.13266V52.0768H36.131V51.6023Z" />
    <path className="color-3" d="M36.131 55.3032H9.13266V55.7777H36.131V55.3032Z" />
    <path className="color-3" d="M36.131 59.0042H9.13266V59.4786H36.131V59.0042Z" />
    <path className="color-3" d="M36.131 62.7053H9.13266V63.1798H36.131V62.7053Z" />
    <path className="color-2" d="M40.0692 34.9004H38.6458V66.2165H40.0692V34.9004Z" />
    <path className="roof-1" d="M40.0692 22.7061H38.6458V34.9003H40.0692V22.7061Z" />
  </svg>
);

const Park = () => (
  <span className="park">
    <svg width="46" height="12" viewBox="0 0 46 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="highlights" d="M26.1948 6.32935C21.5923 6.32935 17.4642 4.05181 17.4168 4.05181L17.654 3.62476C17.7015 3.62476 21.782 5.85486 26.1948 5.85486C30.6549 5.85486 34.4034 3.6722 34.4508 3.62476L34.688 4.05181C34.4983 4.09926 30.7973 6.32935 26.1948 6.32935Z" />
      <path className="highlights" d="M8.82854 6.32935C4.22602 6.32935 0.0979858 4.05181 0.0505371 4.05181L0.287781 3.62476C0.335229 3.62476 4.41582 5.85486 8.82854 5.85486C13.2887 5.85486 17.0372 3.6722 17.0846 3.62476L17.3219 4.05181C17.1321 4.09926 13.3836 6.32935 8.82854 6.32935Z" />
      <path className="highlights" d="M43.2289 6.32935C38.6263 6.32935 34.4983 4.05181 34.4508 4.05181L34.6881 3.62476C34.7355 3.62476 38.8161 5.85486 43.2289 5.85486C43.8931 5.85486 44.6049 5.80741 45.3166 5.71252L45.364 6.18701C44.6523 6.23445 43.9406 6.32935 43.2289 6.32935Z" />
      <path className="color-3" d="M19.1249 11.1692H15.9459V2.48607C15.9459 1.58454 16.6576 0.872803 17.5591 0.872803C18.4607 0.872803 19.1724 1.58454 19.1724 2.48607V11.1692H19.1249Z" />
      <path className="color-3" d="M36.159 11.1692H32.9799V2.48607C32.9799 1.58454 33.6917 0.872803 34.5932 0.872803C35.4947 0.872803 36.2065 1.58454 36.2065 2.48607V11.1692H36.159Z" />
    </svg>
    <svg width="43" height="36" viewBox="0 0 43 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="tree-1" d="M35.0194 23.212C34.2128 23.212 33.4536 23.3544 32.7419 23.5442C31.0338 20.6498 27.8547 18.657 24.2486 18.657C20.0257 18.657 16.3721 21.3141 14.9961 25.11C14.8063 25.0625 14.5691 25.0626 14.3793 25.0626C7.59413 24.9202 4.27274 31.6105 4.27274 35.3115L15.7553 35.264L21.0221 31.7528H27.4751H33.5485H42.5638C42.6112 31.4681 42.6112 31.136 42.6112 30.8038C42.6112 26.6284 39.1949 23.212 35.0194 23.212Z" />
      <path className="tree-2" d="M31.4134 20.887C26.7634 20.887 22.7303 23.7339 21.0696 27.8145C20.2155 27.4823 19.2665 27.34 18.3175 27.34C13.9048 27.34 10.3462 30.8986 10.3462 35.3114H20.7374H26.3364H42.1842C42.4689 34.3149 42.6587 33.2236 42.6587 32.0849C42.6112 25.8691 37.6291 20.887 31.4134 20.887Z" />
      <path className="tree-2" d="M20.7374 11.1126C20.9747 10.3534 21.117 9.54678 21.117 8.6927C21.117 4.04273 17.0364 0.246826 11.9594 0.246826C6.88243 0.246826 2.80186 4.04273 2.80186 8.6927C2.80186 8.9774 2.80185 9.30952 2.8493 9.59421C1.47329 10.211 0.476868 11.5871 0.476868 13.2003C0.476868 15.383 2.23246 17.1386 4.41509 17.1386H11.9594C12.1967 17.1386 12.3865 17.1386 12.6237 17.0911V17.1386H18.9344C20.7374 17.1386 22.2083 15.6676 22.2083 13.8646C22.2083 12.7258 21.5915 11.7294 20.7374 11.1126Z" />
      <path className="tree-1" d="M12.6712 5.1814H11.2477V28.2415H12.6712V5.1814Z" />
      <path className="tree-1" d="M16.6162 9.55844L11.5566 13.0039L12.3579 14.1805L17.4175 10.7351L16.6162 9.55844Z" />
      <path className="tree-1" d="M7.28514 7.45003L6.64316 8.72046L11.5979 11.2242L12.2399 9.95374L7.28514 7.45003Z" />
    </svg>
  </span>
);

const House2 = () => (
  <svg className="house-2" width="101" height="129" viewBox="0 0 101 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="color-2" d="M75.9812 0.247559H75.0322V14.7194H75.9812V0.247559Z" />
    <path className="color-2" d="M100.797 63.7338L91.6868 55.6201H59.3742L50.2641 63.7338V128.264H100.797V63.7338Z" />
    <path className="color-2" d="M91.6867 27.8628L75.5067 11.7302L59.3742 27.8628V55.6203H91.6867V27.8628Z" />
    <path className="window-2" d="M70.0976 80.2935H62.838V89.4985H70.0976V80.2935Z" />
    <path className="color-1" d="M70.7145 89.4985H62.2211V91.1118H70.7145V89.4985Z" />
    <path className="color-3" d="M91.6867 53.3901H59.3742V55.6203H91.6867V53.3901Z" />
    <path className="window-2" d="M79.1603 32.4651H71.9006V41.6701H79.1603V32.4651Z" />
    <path className="color-1" d="M79.7771 41.7175H71.2838V43.3308H79.7771V41.7175Z" />
    <path className="window-2" d="M88.1755 80.2935H80.9159V89.4985H88.1755V80.2935Z" />
    <path className="color-1" d="M88.7924 89.4985H80.2991V91.1118H88.7924V89.4985Z" />
    <path className="window-2" d="M70.0976 105.156H62.838V114.362H70.0976V105.156Z" />
    <path className="color-1" d="M70.7145 114.409H62.2211V116.022H70.7145V114.409Z" />
    <path className="window-2" d="M88.1755 105.156H80.9159V114.362H88.1755V105.156Z" />
    <path className="color-1" d="M88.7924 114.409H80.2991V116.022H88.7924V114.409Z" />
    <path className="highlights" d="M82.8138 51.5396H68.2471V55.6201H82.8138V51.5396Z" />
    <path className="color-3" d="M50.2641 88.8342H4.52353V128.264H50.2641V88.8342Z" />
    <path className="color-1" d="M50.2641 82.4761H0.442932V88.8342H50.2641V82.4761Z" />
    <path className="roof-2" d="M50.2641 88.8342H4.52353V93.1046H50.2641V88.8342Z" />
    <path className="window-2 lights-on" d="M20.1342 98.9409H12.8745V108.146H20.1342V98.9409Z" />
    <path className="color-2" d="M20.7509 108.146H12.2577V109.759H20.7509V108.146Z" />
    <path className="window-2 lights-on" d="M43.3365 98.9409H36.0769V108.146H43.3365V98.9409Z" />
    <path className="color-2" d="M44.0008 108.146H35.5075V109.759H44.0008V108.146Z" />
    <path className="roof-2" d="M50.2641 125.702H4.52353V128.312H50.2641V125.702Z" />
  </svg>
);

const House3 = () => (
  <span className="house-3">
    <svg width="120" height="152" viewBox="0 0 120 152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="color-2" d="M119.057 75.2037L108.381 65.6665H70.2796L59.5562 75.2037V151.264H119.057V75.2037Z" />
      <path className="color-2" d="M108.333 32.9268L89.3066 13.8999L70.2796 32.9268V65.6664H108.333V32.9268Z" />
      <path className="window-2" d="M82.9485 94.7051H74.3602V105.571H82.9485V94.7051Z" />
      <path className="color-1" d="M83.6602 105.571H73.6485V107.469H83.6602V105.571Z" />
      <path className="window-2 lights-on" d="M93.6244 38.3835H85.0362V49.2493H93.6244V38.3835Z" />
      <path className="color-1" d="M94.3361 49.2493H84.3245V51.1472H94.3361V49.2493Z" />
      <path className="window-2 lights-on" d="M104.253 94.7051H95.6647V105.571H104.253V94.7051Z" />
      <path className="color-1" d="M104.965 105.571H94.9529V107.469H104.965V105.571Z" />
      <path className="window-2 lights-on" d="M82.9485 124.028H74.3602V134.894H82.9485V124.028Z" />
      <path className="color-1" d="M83.6602 134.894H73.6485V136.792H83.6602V134.894Z" />
      <path className="window-2" d="M104.253 124.028H95.6647V134.894H104.253V124.028Z" />
      <path className="color-1" d="M104.965 134.894H94.9529V136.792H104.965V134.894Z" />
      <path className="color-2" d="M89.7811 0.424561H88.8321V17.5061H89.7811V0.424561Z" />
      <path className="color-3" d="M108.333 63.2466H70.2796V65.8562H108.333V63.2466Z" />
      <path className="highlights" d="M97.8948 60.8267H80.7183V65.6664H97.8948V60.8267Z" />
      <path className="color-3" d="M59.5562 104.764H5.65454V151.264H59.5562V104.764Z" />
      <path className="color-3" d="M55.8077 97.3147C52.9608 97.3147 50.541 95.3693 49.7818 92.6173C47.694 85.073 40.814 79.5215 32.6054 79.5215C24.3968 79.5215 17.4692 85.073 15.429 92.6173C14.6698 95.3693 12.2499 97.3147 9.403 97.3147H0.862244V104.812H59.6036V97.3147H55.8077Z" />
      <path className="roof-2" d="M59.5562 104.764H5.65454V109.794H59.5562V104.764Z" />
      <path className="roof-2" d="M59.5562 149.651H5.65454V151.311H59.5562V149.651Z" />
      <path className="window-2" d="M24.0647 116.721H15.4764V127.587H24.0647V116.721Z" />
      <path className="color-2" d="M24.7764 127.587H14.7647V129.485H24.7764V127.587Z" />
      <path className="window-2" d="M51.4425 116.721H42.8543V127.587H51.4425V116.721Z" />
      <path className="color-2" d="M52.1542 127.587H42.1426V129.485H52.1542V127.587Z" />
    </svg>
    <svg className="column" width="5" height="79" viewBox="0 0 5 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="color-3" d="M0.49823 0.0246582L4.05688 3.20371V78.9792H0.49823V0.0246582Z" />
    </svg>
  </span>
);

const Castle = () => (
  <svg className="castle" width="211" height="141" viewBox="0 0 211 141" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="color-1" d="M204.772 27.4312H179.434V140.312H204.772V27.4312Z" />
    <path className="color-1" d="M210.513 27.4312H174.025V30.7051H210.513V27.4312Z" />
    <path className="highlights" d="M204.772 30.7051H179.434V33.2673H204.772V30.7051Z" />
    <path className="window lights-on" d="M197.75 56.2325H186.504V44.9397C186.504 43.279 187.833 41.9504 189.493 41.9504H194.808C196.468 41.9504 197.797 43.279 197.797 44.9397V56.2325H197.75Z" />
    <path className="window lights-on" d="M197.75 101.119H186.504V82.7087C186.504 81.048 187.833 79.7195 189.493 79.7195H194.808C196.468 79.7195 197.797 81.048 197.797 82.7087V101.119H197.75Z" />
    <path className="color-1" d="M204.013 5.55728V0.717529H199.505V5.55728H194.523V0.717529H190.015V5.55728H184.749V0.717529H180.241V5.55728L175.211 27.4311H208.995L204.013 5.55728Z" />
    <path className="roof-2" d="M133.409 22.2593H76.5656L64.0866 44.228H145.936L133.409 22.2593Z" />
    <path className="color-1" d="M31.3945 27.4312H6.05688V140.312H31.3945V27.4312Z" />
    <path className="highlights" d="M37.0883 27.4312H0.600281V30.7051H37.0883V27.4312Z" />
    <path className="roof-2" opacity="0.6" d="M31.3945 30.7051H6.05688V33.2673H31.3945V30.7051Z" />
    <path className="window lights-on" d="M24.3246 56.2325H13.0793V44.9397C13.0793 43.279 14.4078 41.9504 16.0685 41.9504H21.3828C23.0435 41.9504 24.3721 43.279 24.3721 44.9397V56.2325H24.3246Z" />
    <path className="window" d="M24.3246 101.119H13.0793V82.7087C13.0793 81.048 14.4078 79.7195 16.0685 79.7195H21.3828C23.0435 79.7195 24.3721 81.048 24.3721 82.7087V101.119H24.3246Z" />
    <path className="color-1" d="M30.5878 5.55728V0.717529H26.0802V5.55728H21.0981V0.717529H16.5905V5.55728H11.3237V0.717529H6.81604V5.55728L1.83392 27.4311H35.5699L30.5878 5.55728Z" />
    <path className="color-1" d="M179.434 65.0105H31.347V140.312H179.434V65.0105Z" />
    <path className="color-1" d="M151.203 44.228H58.8198V65.0105H151.203V44.228Z" />
    <path className="color-1" d="M118.463 29.0918H91.512V44.2754H118.463V29.0918Z" />
    <path className="color-2" d="M112.389 15.4741H97.6328L93.8843 29.0919H116.138L112.389 15.4741Z" />
    <path className="color-2" d="M105.225 0.717529H104.75V15.4741H105.225V0.717529Z" />
    <path className="color-2" d="M64.0866 39.7205H58.8198V44.2755H64.0866V39.7205Z" />
    <path className="color-2" d="M85.3911 39.7205H80.1243V44.2755H85.3911V39.7205Z" />
    <path className="color-2" d="M129.898 39.7205H124.631V44.2755H129.898V39.7205Z" />
    <path className="color-2" d="M151.203 39.7205H145.936V44.2755H151.203V39.7205Z" />
    <path className="color-2" d="M179.482 68.0947H179.434H177.679H33.1026H31.347V71.3687V140.312H33.1026V71.3687H177.679V140.312H179.434V71.3687H179.482V68.0947Z" />
    <path className="highlights" d="M105.414 56.9916C109.974 56.9916 113.67 53.2953 113.67 48.7356C113.67 44.1759 109.974 40.4795 105.414 40.4795C100.855 40.4795 97.1583 44.1759 97.1583 48.7356C97.1583 53.2953 100.855 56.9916 105.414 56.9916Z" />
    <path className="roof-2" d="M58.8198 53.3381H31.347V62.4483H58.8198V53.3381Z" />
    <path className="roof-2" d="M179.482 53.3381H151.203V62.4483H179.482V53.3381Z" />
    <path className="window lights-on" d="M93.7894 100.17H82.5441V81.7598C82.5441 80.0991 83.8727 78.7705 85.5334 78.7705H90.8476C92.5083 78.7705 93.8369 80.0991 93.8369 81.7598V100.17H93.7894Z" />
    <path className="color-1" d="M94.691 100.17H81.5478V102.637H94.691V100.17Z" />
    <path className="window" d="M59.2943 100.17H48.0489V88.8772C48.0489 87.2165 49.3775 85.8879 51.0382 85.8879H56.3525C58.0132 85.8879 59.3418 87.2165 59.3418 88.8772V100.17H59.2943Z" />
    <path className="color-1" d="M60.2433 100.17H47.1V102.637H60.2433V100.17Z" />
    <path className="window lights-on" d="M128.285 100.17H117.039V81.7598C117.039 80.0991 118.368 78.7705 120.029 78.7705H125.343C127.004 78.7705 128.332 80.0991 128.332 81.7598V100.17H128.285Z" />
    <path className="color-1" d="M129.234 100.17H116.09V102.637H129.234V100.17Z" />
    <path className="window" d="M162.78 100.17H151.535V88.8772C151.535 87.2165 152.863 85.8879 154.524 85.8879H159.838C161.499 85.8879 162.827 87.2165 162.827 88.8772V100.17H162.78Z" />
    <path className="color-1" d="M163.729 100.17H150.586V102.637H163.729V100.17Z" />
    <path className="window" d="M59.2943 127.358H48.0489V116.065C48.0489 114.404 49.3775 113.076 51.0382 113.076H56.3525C58.0132 113.076 59.3418 114.404 59.3418 116.065V127.358H59.2943Z" />
    <path className="color-1" d="M60.2433 127.358H47.1V129.825H60.2433V127.358Z" />
    <path className="window" d="M93.7421 127.358H82.4967V116.065C82.4967 114.404 83.8253 113.076 85.486 113.076H90.8002C92.4609 113.076 93.7895 114.404 93.7895 116.065V127.358H93.7421Z" />
    <path className="color-1" d="M94.691 127.358H81.5478V129.825H94.691V127.358Z" />
    <path className="window" d="M128.285 127.358H117.039V116.065C117.039 114.404 118.368 113.076 120.029 113.076H125.343C127.004 113.076 128.332 114.404 128.332 116.065V127.358H128.285Z" />
    <path className="color-1" d="M129.234 127.358H116.09V129.825H129.234V127.358Z" />
    <path className="window" d="M162.78 127.358H151.535V116.065C151.535 114.404 152.863 113.076 154.524 113.076H159.838C161.499 113.076 162.827 114.404 162.827 116.065V127.358H162.78Z" />
    <path className="color-1" d="M163.729 127.358H150.586V129.825H163.729V127.358Z" />
    <path className="roof-2" d="M74.8101 60.5503H67.93V50.5387C67.93 49.5422 68.7366 48.7356 69.733 48.7356H72.9595C73.956 48.7356 74.7626 49.5422 74.7626 50.5387V60.5503H74.8101Z" />
    <path className="roof-2" d="M142.092 60.5503H135.212V50.5387C135.212 49.5422 136.019 48.7356 137.015 48.7356H140.242C141.238 48.7356 142.045 49.5422 142.045 50.5387V60.5503H142.092Z" />
    <path className="color-1" d="M182.518 62.4482H28.0256V68.0947H182.518V62.4482Z" />
    <path className="color-2" d="M31.3945 115.211H6.05688V117.299H31.3945V115.211Z" />
    <path className="color-2" d="M204.772 115.211H179.434V117.299H204.772V115.211Z" />
  </svg>
);

const TownBuildings = () => (
  <div className="landscape__town-buildings">
    <House1 />
    <Park />
    <House2 />
    <House3 />
    <Castle />
  </div>
);

export default TownBuildings;
