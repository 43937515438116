import { HeaderBar, Button, BackButton, ButtonGroup, Grid, GridCol, Heading, DataTable, DataTableHead, DataTableFoot, DataTableRow, Checkbox } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft, IconInfoSolid, IconOfferSolid, IconDeleteSolid } from '@flixbus/honeycomb-icons-react';
import { useHistory } from 'react-router-dom';
import DispatchContext from '../../contexts/DispatchContext';
import StateContext from '../../contexts/StateContext';
import { useContext, useEffect, useState } from 'react';
import ApiCall from '../../helpers/ApiCall';


const Header = () => {
    const appDispatch = useContext(DispatchContext)
    const appState = useContext(StateContext);
    const history = useHistory();

    const [list, setList] = useState();
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteId, setDeleteId] = useState();

    const headerColorStyle = { color: 'var(--hcr-header-color)' };

    const newRequestClick = () => {
        history.push('/new-request');
    }

    useEffect(() => {
        const effectData = async () => {
            await getList();
        }
        effectData();
    }, []);

    const getList = async () => {
        try {
            appDispatch({ type: "startLoader" });
            const result = await ApiCall("authority/GetRequestList", "Get", null);
            if (result?.status === 200)
                setList(result?.data?.data);
        } catch (error) {
            console.log(error);
            await appDispatch({ type: "showNotification", notification: { message: error, appearance: "danger" } });
        }
        finally {
            appDispatch({ type: "stopLoader" });
        }
    }


    const deleteConfirm = (id) => {
        setDeleteId(id);
        setShowDeletePopup(true);
    }

    const getDetail = async (id) => {
        history.push('/requests/detail?id=' + id)
    }


    const deleteRecord = async (id) => {
        try {
            appDispatch({ type: "startLoader" });
            const params = {
                id: id
            }
            const result = await ApiCall("DeleteRequest/" + id, "DELETE", null);
            if (result?.status === 200)
                await getList();
            else
                await appDispatch({ type: "showNotification", notification: { message: "Record couldn't be deleted.", appearance: "danger" } });
        } catch (error) {
            console.log(error);
            await appDispatch({ type: "showNotification", notification: { message: error, appearance: "danger" } });
        }
        finally {
            appDispatch({ type: "stopLoader" });
        }
    }

    return (
        <>
            <Grid align="center" justify='center'>
                <GridCol size={3}>
                    <h1>
                        Submitted requests
                    </h1>
                </GridCol>
            </Grid>
            <DataTable small
                headers={(
                    <DataTableHead>
                        <th scope="col">#</th>
                        <th scope="col">Create Date</th>
                        <th scope="col">Country</th>
                        <th scope="col">Record Type</th>
                        <th scope="col">Status</th>
                        <th scope="col">Processed Authority</th>
                        <th scope="col">Detail</th>
                    </DataTableHead>
                )}>
                {list?.map((item, index) => (
                    <DataTableRow key={index}>
                        <th scope="row">{list?.length - index}</th>
                        <td>{item.createDate}</td>
                        <td>{item.country}</td>
                        <td>{item.recordType}</td>
                        <td>{item.status}</td>
                        <td>{item.processedAuthority ? "Done" : "-"}</td>
                        <td>
                            <ButtonGroup>
                                <Button display="square" appearance="tertiary" aria-label="Info" style={headerColorStyle} onClick={() => getDetail(item?.authorityRequestId)}>
                                    <Icon InlineIcon={IconInfoSolid} color='blue' />
                                </Button>
                            </ButtonGroup>
                        </td>
                    </DataTableRow>
                ))}
            </DataTable>

        </>
    );
}

export default Header;