import { login, logout } from "./loginReducer";
import { startLoader, stopLoader } from "./loadingReducer";

export function combinedReducer(draft, action) {
  switch (action.type) {
    case "login":
      login(draft, action);
      return;
    case "logout":
      logout(draft);
      return;
    case "startLoader":
      startLoader(draft);
      return;
    case "stopLoader":
      stopLoader(draft);
      return;
    default:
      console.log("default", draft, action)
      return;
  }
}
