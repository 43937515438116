import React from 'react';
import './skyline.scss';

const Windmill = () => (
  <>
    <svg className="windmill-1 color-1" width="27" height="40" viewBox="0 0 27 40" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.24528 17.5987C7.15038 17.6936 7.00803 17.7885 6.91313 17.8834C5.25243 19.212 3.54429 20.6354 2.26318 22.3436C3.59174 21.9166 5.82182 20.8252 8.19425 19.4492C8.76363 19.1171 9.33301 18.7849 9.85495 18.4528C10.4243 17.5038 10.8988 16.5074 11.3259 15.511C10.1397 15.9855 8.71618 16.7446 7.24528 17.5987Z" />
      <path d="M6.91312 17.836C7.00802 17.7411 7.15037 17.6462 7.24527 17.5513C7.19782 17.5513 7.19782 17.5987 7.15037 17.5987C3.40193 19.7814 0.602482 21.9166 0.887173 22.391C0.98207 22.5808 1.50399 22.4859 2.26317 22.2487C3.54428 20.588 5.25242 19.212 6.91312 17.836Z" />
      <path d="M14.4575 14.6094C14.2677 14.3247 13.0815 14.6568 11.3259 15.4635C10.8988 16.4599 10.4243 17.4563 9.85495 18.4053C12.7493 16.6497 14.6947 15.0364 14.4575 14.6094Z" />
      <path d="M13.6034 0.422119C13.1289 0.422119 12.7493 2.69965 12.607 5.87871C12.9866 4.3129 13.5085 2.79456 14.0779 1.32365C13.9355 0.754271 13.7932 0.422119 13.6034 0.422119Z" />
      <path d="M12.607 5.87863C12.5595 6.63781 12.5595 7.39699 12.5595 8.25107C12.5595 9.81687 12.607 11.2403 12.7019 12.4266C13.4611 11.2878 14.0779 10.1016 14.5998 8.86791C14.5998 8.63067 14.5998 8.44087 14.5998 8.20363C14.5998 5.16692 14.3626 2.55724 14.0304 1.27612C13.5085 2.79448 13.034 4.31283 12.607 5.87863Z" />
      <path d="M13.6034 16.0803C14.1253 16.0803 14.5998 12.9487 14.6473 8.91553C14.1253 10.1492 13.4611 11.3354 12.7493 12.4742C12.9391 14.6094 13.2238 16.0803 13.6034 16.0803Z" />
      <path d="M12.7493 14.6093C12.5121 15.0364 14.4575 16.6496 17.3518 18.4052C16.5927 17.5037 15.9758 16.4598 15.5963 15.3211C14.0305 14.6093 12.9391 14.3246 12.7493 14.6093Z" />
      <path d="M25.608 21.3945C24.4217 21.3945 23.2355 21.2521 22.0493 21.0149C24.3743 22.1537 26.1299 22.7705 26.3197 22.4383C26.4146 22.2486 26.1299 21.869 25.608 21.3945Z" />
      <path d="M20.0564 17.646C18.3957 16.697 16.8774 15.8903 15.5963 15.3684C15.9758 16.5072 16.5926 17.551 17.3518 18.4526C17.8738 18.7847 18.4431 19.1169 19.0125 19.449C20.0564 20.0658 21.1003 20.5878 22.0018 21.0623C23.188 21.2995 24.3742 21.3944 25.5605 21.4418C24.564 20.4454 22.5237 19.022 20.0564 17.646Z" />
      <path d="M13.8881 19.6863C13.5085 19.0694 13.0815 18.5 12.6544 17.9307L11.4682 38.903C11.4682 39.2826 12.4172 39.5672 13.5559 39.5672C13.6508 39.5672 13.7457 39.5672 13.8406 39.5672C14.3626 33.4464 14.1253 25.0954 13.8881 19.6863Z" />
      <path d="M13.6508 14.5618H12.8917L12.7019 17.9306C13.1289 18.5 13.556 19.0694 13.9355 19.6862C13.7932 17.2189 13.6983 15.3209 13.6508 14.5618Z" />
      <path d="M14.7422 21.0622L14.3626 14.5618H13.6508C13.6983 15.3209 13.7932 17.2189 13.8881 19.6862C14.1728 20.1132 14.4575 20.5877 14.7422 21.0622Z" />
      <path d="M13.8881 19.6863C14.1253 25.0954 14.3626 33.4464 13.9356 39.5672C14.932 39.5198 15.7386 39.2351 15.7386 38.903L14.7422 21.0623C14.4575 20.5878 14.1728 20.1133 13.8881 19.6863Z" />
    </svg>

    <svg className="windmill-2 color-1" width="27" height="40" viewBox="0 0 27 40" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.24528 17.5987C7.15038 17.6936 7.00803 17.7885 6.91313 17.8834C5.25243 19.212 3.54429 20.6354 2.26318 22.3436C3.59174 21.9166 5.82182 20.8252 8.19425 19.4492C8.76363 19.1171 9.33301 18.7849 9.85495 18.4528C10.4243 17.5038 10.8988 16.5074 11.3259 15.511C10.1397 15.9855 8.71618 16.7446 7.24528 17.5987Z" />
      <path d="M6.91312 17.836C7.00802 17.7411 7.15037 17.6462 7.24527 17.5513C7.19782 17.5513 7.19782 17.5987 7.15037 17.5987C3.40193 19.7814 0.602482 21.9166 0.887173 22.391C0.98207 22.5808 1.50399 22.4859 2.26317 22.2487C3.54428 20.588 5.25242 19.212 6.91312 17.836Z" />
      <path d="M14.4575 14.6094C14.2677 14.3247 13.0815 14.6568 11.3259 15.4635C10.8988 16.4599 10.4243 17.4563 9.85495 18.4053C12.7493 16.6497 14.6947 15.0364 14.4575 14.6094Z" />
      <path d="M13.6034 0.422119C13.1289 0.422119 12.7493 2.69965 12.607 5.87871C12.9866 4.3129 13.5085 2.79456 14.0779 1.32365C13.9355 0.754271 13.7932 0.422119 13.6034 0.422119Z" />
      <path d="M12.607 5.87863C12.5595 6.63781 12.5595 7.39699 12.5595 8.25107C12.5595 9.81687 12.607 11.2403 12.7019 12.4266C13.4611 11.2878 14.0779 10.1016 14.5998 8.86791C14.5998 8.63067 14.5998 8.44087 14.5998 8.20363C14.5998 5.16692 14.3626 2.55724 14.0304 1.27612C13.5085 2.79448 13.034 4.31283 12.607 5.87863Z" />
      <path d="M13.6034 16.0803C14.1253 16.0803 14.5998 12.9487 14.6473 8.91553C14.1253 10.1492 13.4611 11.3354 12.7493 12.4742C12.9391 14.6094 13.2238 16.0803 13.6034 16.0803Z" />
      <path d="M12.7493 14.6093C12.5121 15.0364 14.4575 16.6496 17.3518 18.4052C16.5927 17.5037 15.9758 16.4598 15.5963 15.3211C14.0305 14.6093 12.9391 14.3246 12.7493 14.6093Z" />
      <path d="M25.608 21.3945C24.4217 21.3945 23.2355 21.2521 22.0493 21.0149C24.3743 22.1537 26.1299 22.7705 26.3197 22.4383C26.4146 22.2486 26.1299 21.869 25.608 21.3945Z" />
      <path d="M20.0564 17.646C18.3957 16.697 16.8774 15.8903 15.5963 15.3684C15.9758 16.5072 16.5926 17.551 17.3518 18.4526C17.8738 18.7847 18.4431 19.1169 19.0125 19.449C20.0564 20.0658 21.1003 20.5878 22.0018 21.0623C23.188 21.2995 24.3742 21.3944 25.5605 21.4418C24.564 20.4454 22.5237 19.022 20.0564 17.646Z" />
      <path d="M13.8881 19.6863C13.5085 19.0694 13.0815 18.5 12.6544 17.9307L11.4682 38.903C11.4682 39.2826 12.4172 39.5672 13.5559 39.5672C13.6508 39.5672 13.7457 39.5672 13.8406 39.5672C14.3626 33.4464 14.1253 25.0954 13.8881 19.6863Z" />
      <path d="M13.6508 14.5618H12.8917L12.7019 17.9306C13.1289 18.5 13.556 19.0694 13.9355 19.6862C13.7932 17.2189 13.6983 15.3209 13.6508 14.5618Z" />
      <path d="M14.7422 21.0622L14.3626 14.5618H13.6508C13.6983 15.3209 13.7932 17.2189 13.8881 19.6862C14.1728 20.1132 14.4575 20.5877 14.7422 21.0622Z" />
      <path d="M13.8881 19.6863C14.1253 25.0954 14.3626 33.4464 13.9356 39.5672C14.932 39.5198 15.7386 39.2351 15.7386 38.903L14.7422 21.0623C14.4575 20.5878 14.1728 20.1133 13.8881 19.6863Z" />
    </svg>

    <svg className="windmill-3 color-3" width="40" height="53" viewBox="0 0 40 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.91 45.8892H11.9673V52.2473H27.91V45.8892Z" />
      <path d="M24.0668 20.1721H15.7632L13.9127 45.8893H25.9174L24.0668 20.1721Z" />
      <path d="M19.9387 10.7773L13.4382 20.1722H26.4867L19.9387 10.7773Z" />
      <path d="M24.6836 41.1443H23.1653V41.9035H24.6836V41.1443Z" />
      <path d="M7.23901 2.46092L6.4054 3.08911L19.8261 20.8986L20.6597 20.2704L7.23901 2.46092Z" />
      <path d="M10.884 0.744749L7.70105 3.14331L10.4137 6.7431L13.5967 4.34454L10.884 0.744749Z" />
      <path d="M13.6274 4.33215L10.4445 6.73071L13.1571 10.3305L16.3401 7.93194L13.6274 4.33215Z" />
      <path d="M16.3328 7.94812L13.1499 10.3467L15.8626 13.9465L19.0455 11.5479L16.3328 7.94812Z" />
      <path d="M37.6395 7.42248L37.0113 6.58887L19.2018 20.0095L19.83 20.8432L37.6395 7.42248Z" />
      <path d="M39.3847 11.0911L36.9861 7.9082L33.3864 10.6209L35.7849 13.8038L39.3847 11.0911Z" />
      <path d="M35.7898 13.8046L33.3912 10.6216L29.7914 13.3343L32.19 16.5173L35.7898 13.8046Z" />
      <path d="M32.1569 16.5467L29.7583 13.3638L26.1584 16.0765L28.557 19.2594L32.1569 16.5467Z" />
      <path d="M32.6787 37.8377L33.5125 37.2095L20.0919 19.4L19.2582 20.0282L32.6787 37.8377Z" />
      <path d="M29.0093 39.5971L32.1924 37.1985L29.4796 33.5987L26.2965 35.9973L29.0093 39.5971Z" />
      <path d="M26.3132 35.9655L29.4961 33.5669L26.7834 29.9671L23.6005 32.3657L26.3132 35.9655Z" />
      <path d="M23.5788 32.3625L26.7617 29.9639L24.0491 26.3641L20.8662 28.7627L23.5788 32.3625Z" />
      <path d="M2.2622 32.8622L2.89038 33.6958L20.6997 20.2751L20.0715 19.4415L2.2622 32.8622Z" />
      <path d="M0.494146 29.245L2.89282 32.428L6.49268 29.7153L4.09401 26.5323L0.494146 29.245Z" />
      <path d="M4.11816 26.5185L6.51669 29.7014L10.1164 26.9887L7.71791 23.8058L4.11816 26.5185Z" />
      <path d="M7.7417 23.7921L10.1404 26.9751L13.7402 24.2624L11.3416 21.0794L7.7417 23.7921Z" />
    </svg>
  </>
);

const Skyline = () => (
  <div className="landscape__skyline">
    <svg className="buildings-1 color-2" width="177" height="82" viewBox="0 0 177 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.787 29.552H0.399994V80.9389H51.787V29.552Z" />
      <path d="M131.833 9.43368L101.371 0.703125V81.0812L131.833 80.9863V9.43368Z" />
      <path d="M176.055 28.176H73.2812V81.1288H176.055V28.176Z" />
      <path d="M30.1978 2.88574L74.42 11.5688V81.0811H30.1978V2.88574Z" />
    </svg>

    <svg className="buildings-2 color-2" width="156" height="92" viewBox="0 0 156 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M113.128 21.43L155.215 13.9331V91.7015H113.128V21.43Z" />
      <path d="M113.128 0.125488H79.7239V91.8438L113.128 91.7015V0.125488Z" />
      <path d="M79.7239 25.4155H48.9771V91.8437H79.7239V25.4155Z" />
      <path d="M48.9771 7.81226L0.532043 17.3494V91.8439H48.9771V7.81226Z" />
      <path className="window lights-on" d="M40.0568 29.6384H12.6314V33.9088H40.0568V29.6384Z" />
      <path className="window lights-on" d="M40.0568 42.0227H12.6314V46.2931H40.0568V42.0227Z" />
    </svg>

    <svg className="buildings-3 color-3" width="96" height="84" viewBox="0 0 96 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.8093 22.4155H0.422363V83.7192H51.8093V22.4155Z" />
      <path d="M95.9841 17.0066H67.8944V57.3854H95.9841V17.0066Z" />
      <path d="M23.6722 0.779053L67.8944 9.46217V61.6557H23.6722V0.779053Z" />
    </svg>

    <svg className="buildings-4 color-3" width="156" height="93" viewBox="0 0 156 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M113.198 17.0064L155.285 9.50952V88.132H113.198V17.0064Z" />
      <path d="M113.198 0.921387H79.7939V92.5922L113.198 92.4499V0.921387Z" />
      <path d="M79.7939 25.4998H49.0471V83.577H79.7939V25.4998Z" />
      <path d="M49.0471 2.06006L0.554382 11.5972V92.5921H49.0471V2.06006Z" />
    </svg>

    <svg className="window-1 window" width="28" height="5" viewBox="0 0 28 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.9076 0.380859H0.4823V4.65126H27.9076V0.380859Z" />
    </svg>

    <svg className="window-2 window lights-on" width="28" height="5" viewBox="0 0 28 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.9076 0.380859H0.4823V4.65126H27.9076V0.380859Z" />
    </svg>

    <svg className="window-3 window" width="19" height="31" viewBox="0 0 19 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="lights-on" d="M5.34525 0.17749H0.125916V8.95547H5.34525V0.17749Z" />
      <path d="M18.7733 0.17749H13.5538V8.95547H18.7733V0.17749Z" />
      <path d="M5.34525 21.7666H0.125916V30.5446H5.34525V21.7666Z" />
      <path className="lights-on" d="M18.7733 21.7666H13.5538V30.5446H18.7733V21.7666Z" />
    </svg>

    <svg className="window-4 window" width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="lights-on" d="M16.3834 0.788086H0.772766V3.82481H16.3834V0.788086Z" />
      <path className="lights-on" d="M16.3834 11.2268H0.772766V14.2635H16.3834V11.2268Z" />
      <path d="M16.3834 21.7129H0.772766V24.7496H16.3834V21.7129Z" />
    </svg>

    <svg className="window-5 window" width="34" height="10" viewBox="0 0 34 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="lights-on" d="M6.02991 0.499756H0.33606V9.46756H6.02991V0.499756Z" />
      <path className="lights-on" d="M19.7426 0.499756H14.0488V9.46756H19.7426V0.499756Z" />
      <path d="M33.0282 0.499756H27.3343V9.46756H33.0282V0.499756Z" />
    </svg>

    <svg className="window-6 window" width="35" height="10" viewBox="0 0 35 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="lights-on" d="M6.02991 0.442627H0.33606V9.41042H6.02991V0.442627Z" />
      <path d="M19.7426 0.442627H14.0488V9.41042H19.7426V0.442627Z" />
      <path className="lights-on" d="M34.7838 0.442627H29.0898V9.41042H34.7838V0.442627Z" />
    </svg>

    <svg className="hill-1 color-1" width="453" height="117" viewBox="0 0 453 117" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0621338 112.782L67.4867 61.3481C67.4867 61.3481 162.574 7.58875 198.587 1.61022C226.345 -2.9923 265.49 7.58876 283.52 17.3157C329.688 42.1314 350.233 27.707 376.093 27.1376C446.554 25.4769 452.912 116.531 452.912 116.531L0.0621338 112.782Z" />
    </svg>

    <svg className="hill-2 color-3" width="270" height="81" viewBox="0 0 270 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.72876 80.3103C0.72876 80.3103 16.5766 45.1033 51.9733 30.5366C87.37 15.9698 145.352 22.5652 163.762 11.8893C182.173 1.2133 200.962 -5.99889 224.686 11.6995C254.627 34.0478 269.763 13.3127 269.763 78.9342L0.72876 80.3103Z" />
    </svg>

    <Windmill />
  </div>
);

export default Skyline;
