import React from 'react';
import './green-hills.scss';

const Windmill = () => (
  <svg className="landscape__windmill" width="124" height="167" viewBox="0 0 124 167" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="body-color-highlights" d="M87.9149 145.99H35.7213V166.677H87.9149V145.99Z" />
    <path className="body-color-1" d="M75.1986 61.8157H48.2003L42.1268 145.99H81.3194L75.1986 61.8157Z" />
    <path className="body-color-1" d="M61.8181 31.1638L40.5136 61.8157H83.1225L61.8181 31.1638Z" />
    <path className="body-color-details" d="M48.3426 61.8157L48.0579 65.7539H75.6256L75.3409 61.8157H48.3426Z" />
    <path className="window-color" d="M61.7232 132.324C59.4931 132.324 57.7375 134.128 57.7375 136.31V145.99H65.7563V136.31C65.7089 134.128 63.9058 132.324 61.7232 132.324Z" />
    <path className="window-color" d="M61.7232 116.524C60.2523 116.524 59.0186 115.29 59.0186 113.82V109.881C59.0186 108.41 60.2523 107.177 61.7232 107.177C63.1941 107.177 64.4278 108.41 64.4278 109.881V113.82C64.4278 115.338 63.1941 116.524 61.7232 116.524Z" />
    <path className="body-color-details" d="M74.7716 135.456H69.7894V137.923H74.7716V135.456Z" />
    <path className="body-color-details" d="M77.2863 130.427H72.3042V132.894H77.2863V130.427Z" />
    <path className="body-color-details" d="M62.6721 67.3673L75.1511 82.3611L76.4797 81.2223L63.1941 65.9438L62.6721 67.3673Z" />
    <path className="body-color-details" d="M52.3758 98.8731H45.6381L45.8279 96.4058H52.3758V98.8731Z" />
    <path className="body-color-2" d="M7.03041 107.234L9.24072 109.777L64.2083 61.9904L61.998 59.448L7.03041 107.234Z" />
    <path className="body-color-2" d="M0.47834 95.7579L8.97711 105.534L20.1139 95.8522L11.6151 86.0762L0.47834 95.7579Z" />
    <path className="body-color-2" d="M11.6412 86.056L20.14 95.832L31.2767 86.1503L22.7779 76.3743L11.6412 86.056Z" />
    <path className="body-color-2" d="M22.8041 76.3539L31.3029 86.1299L42.4396 76.4481L33.9408 66.6722L22.8041 76.3539Z" />
    <path className="body-color-details" d="M23.1515 76.0446L22.4353 76.6672L30.9341 86.4432L31.6503 85.8206L23.1515 76.0446Z" />
    <path className="body-color-details" d="M11.9916 85.7299L11.2754 86.3525L19.7742 96.1285L20.4904 95.5059L11.9916 85.7299Z" />
    <path className="window-color" opacity="0.3" d="M7.83235 104.175L8.98419 105.5L42.4303 76.4233L41.2784 75.0984L7.83235 104.175Z" />
    <path className="body-color-2" d="M16.4402 6.98623L13.8977 9.19653L61.6839 64.1641L64.2264 61.9538L16.4402 6.98623Z" />
    <path className="body-color-2" d="M27.9196 0.478027L18.1436 8.97681L27.8253 20.1136L37.6013 11.6148L27.9196 0.478027Z" />
    <path className="body-color-2" d="M37.6022 11.6377L27.8262 20.1365L37.508 31.2732L47.284 22.7744L37.6022 11.6377Z" />
    <path className="body-color-2" d="M47.2848 22.7973L37.5089 31.2961L47.1906 42.4329L56.9666 33.9341L47.2848 22.7973Z" />
    <path className="body-color-details" d="M46.9747 22.4341L37.1987 30.9329L37.8213 31.6491L47.5973 23.1503L46.9747 22.4341Z" />
    <path className="body-color-details" d="M37.2921 11.2739L27.5161 19.7727L28.1387 20.4889L37.9147 11.9901L37.2921 11.2739Z" />
    <path className="window-color" opacity="0.3" d="M19.4567 7.83008L18.1318 8.98193L47.2082 42.428L48.5332 41.2762L19.4567 7.83008Z" />
    <path className="body-color-2" d="M116.648 16.3872L114.438 13.8447L59.4703 61.6309L61.6806 64.1734L116.648 16.3872Z" />
    <path className="body-color-2" d="M123.184 27.8585L114.685 18.0825L103.549 27.7643L112.048 37.5403L123.184 27.8585Z" />
    <path className="body-color-2" d="M112.008 37.5753L103.509 27.7993L92.3726 37.4811L100.871 47.2571L112.008 37.5753Z" />
    <path className="body-color-2" d="M100.868 47.2614L92.369 37.4854L81.2323 47.1671L89.731 56.9431L100.868 47.2614Z" />
    <path className="body-color-details" d="M92.7123 37.1921L91.9961 37.8147L100.495 47.5907L101.211 46.9681L92.7123 37.1921Z" />
    <path className="body-color-details" d="M103.909 27.4814L103.193 28.104L111.692 37.88L112.408 37.2574L103.909 27.4814Z" />
    <path className="window-color" opacity="0.3" d="M115.842 19.4285L114.69 18.1035L81.2444 47.1799L82.3963 48.5049L115.842 19.4285Z" />
    <path className="body-color-2" d="M107.253 116.64L109.795 114.43L62.0091 59.4626L59.4666 61.6729L107.253 116.64Z" />
    <path className="body-color-2" d="M95.7537 123.185L105.53 114.686L95.848 103.549L86.072 112.048L95.7537 123.185Z" />
    <path className="body-color-2" d="M86.0843 112.01L95.8603 103.511L86.1785 92.3747L76.4026 100.873L86.0843 112.01Z" />
    <path className="body-color-2" d="M76.379 100.867L86.155 92.3679L76.4733 81.2311L66.6973 89.73L76.379 100.867Z" />
    <path className="body-color-details" d="M85.8251 91.999L76.0491 100.498L76.6717 101.214L86.4477 92.7152L85.8251 91.999Z" />
    <path className="body-color-details" d="M95.5435 103.128L85.7675 111.627L86.3901 112.343L96.1661 103.844L95.5435 103.128Z" />
    <path className="window-color" opacity="0.3" d="M104.204 115.846L105.529 114.694L76.453 81.2478L75.128 82.3996L104.204 115.846Z" />
    <path className="body-color-1" d="M66.6104 69.8821L68.9828 67.3199L65.7563 63.7612L63.4313 66.2286L66.6104 69.8821Z" />
    <path className="body-color-highlights" d="M61.818 67.3674C64.8841 67.3674 67.3695 64.8819 67.3695 61.8159C67.3695 58.7499 64.8841 56.2644 61.818 56.2644C58.752 56.2644 56.2665 58.7499 56.2665 61.8159C56.2665 64.8819 58.752 67.3674 61.818 67.3674Z" />
    <path className="body-color-2" d="M61.8181 64.5205C63.3118 64.5205 64.5227 63.3096 64.5227 61.8159C64.5227 60.3222 63.3118 59.1113 61.8181 59.1113C60.3244 59.1113 59.1135 60.3222 59.1135 61.8159C59.1135 63.3096 60.3244 64.5205 61.8181 64.5205Z" />
    <path className="body-color-details" d="M67.5119 90.6646L66.041 91.8508L78.6149 106.56L78.3302 103.096L67.5119 90.6646Z" />
    <path className="window-color lights-on" d="M64.665 83.31H58.6865V78.7549C58.6865 77.0942 60.015 75.7656 61.6757 75.7656C63.3364 75.7656 64.665 77.0942 64.665 78.7549V83.31Z" />
    <path className="body-color-2" d="M65.1869 83.3101H58.212V84.6386H65.1869V83.3101Z" />
    <path className="base-color" d="M35.7213 161.6V156.808C35.7213 156.808 44.4993 157.045 48.5799 156.333C55.9819 155.052 64.8073 153.012 72.2568 152.49C82.5057 151.778 87.8674 154.151 87.8674 154.151V160.936L35.7213 161.6Z" fill="#DAF4C9" />
    <path className="window-color" opacity="0.6" d="M81.3194 143.902H42.1268V145.99H81.3194V143.902Z" />
  </svg>
);

const GreenHills = () => (
  <div className="landscape__green-hills">
    <svg className="green-hills-1 color-1" width="298" height="110" viewBox="0 0 298 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M68.7542 104.045L0.475586 26.0864C0.475586 26.0864 63.7721 -3.37928 99.928 1.41304C141.256 6.86964 151.267 25.6119 168.634 36.4302C193.781 52.0408 231.883 37.047 258.027 50.0954C297.694 69.8815 297.22 109.359 297.22 109.359L68.7542 104.045Z" />
    </svg>

    <svg className="green-hills-2 color-1" width="152" height="50" viewBox="0 0 152 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M151.174 18.185C151.174 18.185 129.965 -7.20001 96.2289 3.47594C81.7096 8.07846 76.3954 20.3202 61.4965 20.6523C46.5976 20.9845 27.6655 22.0284 17.6539 27.8171C2.18561 36.7374 0.714722 49.2639 0.714722 49.2639H127.735L151.174 18.185Z" />
    </svg>

    <svg className="green-hills-2-shadow color-shadow-1" width="181" height="5" viewBox="0 0 181 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M69.2322 4.31155H180.31V0.373291L0.621338 4.31155H69.2322Z" />
    </svg>

    <Windmill />

    <svg className="green-hills-3 color-2" width="268" height="102" viewBox="0 0 268 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 101.885V48.742C0 19.2764 26.1917 -3.54645 55.3252 1.05607C55.7522 1.10352 56.1792 1.19841 56.6537 1.29331C101.683 9.16979 100.259 47.6507 117.957 57.0455C135.656 66.4403 184.955 54.1037 210.15 56.571C258.263 61.2684 267.848 101.932 267.848 101.932H0V101.885Z" />
    </svg>

    <svg className="green-hills-3-shadow color-shadow-2" width="258" height="9" viewBox="0 0 258 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M257.694 8.21657V6.50843L0 0.0554199V8.26404H170.151L257.694 8.21657Z" />
    </svg>

    <svg className="green-hills-4 color-2" width="112" height="47" viewBox="0 0 112 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.590576 46.3114H111.953V0.0964355C111.953 0.0964355 105.974 2.61122 94.0645 6.50201C82.2023 10.3928 79.4502 1.425 54.4922 7.97292C35.0383 13.0974 35.0382 27.0947 24.4572 28.0912C2.96292 30.1789 0.590576 46.3114 0.590576 46.3114Z" />
    </svg>
  </div>
);

export default GreenHills;
