import React from 'react';
import Sky from './sky';
import Skyline from './skyline';
import Railroad from './railroad';
import GreenHills from './green-hills';
import TownBuildings from './town-buildings';
import Road from './road';
import './landscape.scss';

const Landscape = () => {

  return (
    <div className={`landscape`}>
      <Sky />
      <Skyline />
      <Railroad />
      <GreenHills />
      <TownBuildings />
      <Road />
    </div>
  );
};

export default Landscape;
