import React from 'react';
import './sky.scss';

const Sky = () => (
  <>
    <svg className="landscape__sky" width="1221" height="274" viewBox="0 0 1221 274" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="sky-1" d="M1197.68 24.4361L64.2252 100.591V114.873C64.2252 117.768 61.9951 120.188 59.1007 120.377L39.0299 121.801V252.237C39.0299 264.194 48.662 273.921 60.5716 273.921L973.627 264.526L1199.15 273.921C1211.06 273.921 1220.69 264.242 1220.69 252.237V46.0727C1220.69 33.4988 1210.11 23.582 1197.68 24.4361Z" />
      <path className="sky-2" d="M51.0344 101.54L64.2252 100.591V84.5535C64.2252 81.327 61.5206 78.8122 58.2941 79.0494L5.81586 82.7979C2.9215 82.9877 0.691406 85.4076 0.691406 88.3019V118.622C0.691406 121.848 3.39598 124.363 6.62249 124.126L39.0299 121.801V114.494C39.0299 107.661 44.2967 102.015 51.0344 101.54Z" />
      <path className="sky-3" d="M64.2252 114.873V100.591L51.0344 101.54C44.2493 102.015 39.0299 107.661 39.0299 114.494V121.801L59.1007 120.377C61.9951 120.187 64.2252 117.815 64.2252 114.873Z" />
      <path className="sky-2" d="M642.435 8.35087C642.435 3.5111 638.354 -0.284793 633.514 0.0473481L127.047 36.1558C122.682 36.4879 119.313 40.094 119.313 44.4593V96.6529L642.435 59.5005V8.35087Z" />
      <path className="sky-4" d="M642.435 228.75V59.5481L119.313 96.7004V165.121L642.435 228.75Z" />
    </svg>
    <div className="landscape__clouds">
      <svg className="cloud-1" width="36" height="15" viewBox="0 0 36 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.302 9.22254C29.78 9.22254 29.2581 9.31744 28.7362 9.45978C27.7872 4.14553 23.1372 0.0649414 17.5383 0.0649414C13.173 0.0649414 9.37711 2.53228 7.47916 6.18583C7.43171 6.18583 7.33681 6.18583 7.28937 6.18583C3.49347 6.18583 0.409302 9.26999 0.409302 13.0659C0.409302 13.6353 0.456751 14.1572 0.599097 14.6317H6.67253H14.0271H25.4622H28.4989H35.2366C35.2366 14.4419 35.2841 14.2521 35.2841 14.1097C35.1892 11.4052 33.0066 9.22254 30.302 9.22254Z" />
      </svg>
      <svg className="cloud-2" width="46" height="19" viewBox="0 0 46 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M38.7137 11.829C38.002 11.829 37.3377 11.9239 36.7209 12.1612C35.5347 5.28112 29.5087 0.0617676 22.2965 0.0617676C16.6501 0.0617676 11.7629 3.24083 9.29553 7.93825C9.20063 7.93825 9.10575 7.93825 9.01085 7.93825C4.07619 7.93825 0.137939 11.9239 0.137939 16.8111C0.137939 17.5229 0.232837 18.1872 0.375183 18.8514H8.25168H17.694H32.4505H36.3888H45.0719C45.1193 18.6142 45.1193 18.377 45.1193 18.1397C45.0244 14.676 42.1775 11.829 38.7137 11.829Z" />
      </svg>
      <svg className="cloud-3" width="30" height="13" viewBox="0 0 30 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.742 7.87647C25.2675 7.87647 24.8404 7.97137 24.4134 8.06626C23.6068 3.55864 19.716 0.142334 14.9711 0.142334C11.2701 0.142334 8.04363 2.23008 6.47782 5.31424C6.43038 5.31424 6.33547 5.31424 6.28802 5.31424C3.06151 5.31424 0.451843 7.92392 0.451843 11.1504C0.451843 11.6249 0.499286 12.052 0.594183 12.479H5.71863H11.8869H21.5665H24.1287H29.8225C29.8225 12.3366 29.87 12.1943 29.87 12.0045C29.9174 9.72697 28.0669 7.87647 25.742 7.87647Z" />
      </svg>
      <svg className="cloud-4" width="52" height="21" viewBox="0 0 52 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.30828 13.1111C8.11491 13.1111 8.874 13.2535 9.58573 13.4432C10.9617 6.0887 17.7945 0.489746 26.0506 0.489746C32.5036 0.489746 38.1025 3.90605 40.902 8.93562C40.9968 8.93562 41.0918 8.93562 41.2342 8.93562C46.8331 8.93562 51.3882 13.206 51.3882 18.4728C51.3882 19.232 51.2932 19.9437 51.1034 20.6554H42.1357H31.3648H14.5205H10.0602H0.190956C0.143507 20.4182 0.143461 20.181 0.143461 19.8963C0.0960122 16.1478 3.32258 13.1111 7.30828 13.1111Z" />
      </svg>
      <svg className="cloud-5" width="39" height="17" viewBox="0 0 39 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.2412 10.6931C32.6718 10.6931 32.1024 10.788 31.5805 10.9778C30.5841 5.23646 25.5545 0.82373 19.4811 0.82373C14.7362 0.82373 10.6556 3.48086 8.56789 7.4191C8.47299 7.4191 8.42554 7.4191 8.33064 7.4191C4.20261 7.4191 0.881226 10.7405 0.881226 14.8685C0.881226 15.4379 0.928686 16.0073 1.07103 16.5767H7.66641H15.5903H27.9744H31.2484H38.508C38.508 16.3869 38.5555 16.1971 38.5555 16.0073C38.5555 13.0655 36.183 10.6931 33.2412 10.6931Z" />
      </svg>
      <svg className="cloud-6" width="28" height="10" viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5121 4.80502C21.2274 4.80502 20.9427 4.80501 20.658 4.85246C19.2819 2.00554 16.3402 0.0126953 12.9713 0.0126953C9.55501 0.0126953 6.66061 2.00554 5.2846 4.85246C4.9999 4.80501 4.71525 4.75756 4.43055 4.75756C2.01067 4.75756 0.0652466 6.70296 0.0652466 9.12284H4.47799H8.84324H15.9605H21.5121H27.111C26.4941 6.70296 24.2166 4.80502 21.5121 4.80502Z" />
      </svg>
    </div>
  </>
);

export default Sky;
