import { Grid, GridCol, FormRow, Header, Heading, Button } from "@flixbus/honeycomb-react";
import { loginRequest } from "../../configs/authConfig";
import { useMsal } from "@azure/msal-react";

const LoginMessage = () => {

    const { accounts, instance } = useMsal();

    const handleLogin = () => {
        localStorage.clear();
        instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

    return (
        <>
            <Grid justify="center" extraClasses="flix-space-8-top">
                <Heading size={2}>
                    Data Sharing Portal Login
                </Heading>
            </Grid >
            <Grid justify="center" extraClasses="flix-space-8-top">
                <Button appearance="secondary" onClick={handleLogin}>
                    Login
                </Button>
            </Grid >
        </>
    );
}

export default LoginMessage;