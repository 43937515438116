import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import StateContext from "../../../contexts/StateContext";
import DispatchContext from "../../../contexts/DispatchContext";
import ApiCall from "../../../helpers/ApiCall";
import { HeaderBar, Button, BackButton, ButtonGroup, Grid, GridCol, Heading, DataTable, DataTableHead, DataTableFoot, DataTableRow, Checkbox, Accordion, Fieldset, Switch, Dropdown, DropdownItem } from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft, IconUpload, IconDownload, IconOfferSolid, IconDeleteSolid, IconSettingsSolid, IconSave } from '@flixbus/honeycomb-icons-react';
import StatusChangePopup from "./StatusChangePopup";
const RequestDetail = () => {

    const appDispatch = useContext(DispatchContext)
    const appState = useContext(StateContext);

    const [detail, setDetail] = useState();
    const [statusList, setStatusList] = useState();
    const [status, setStatus] = useState();
    const [statusPopup, setStatusPopup] = useState(false);
    const [files, setFiles] = useState();


    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        setFiles(Array.from(event.target.files));
    };

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    let id = query.get("id");

    useEffect(() => {
        const effectData = async () => {
            !statusPopup && await getDetail();
        }
        effectData();
    }, [statusPopup]);

    // useEffect(() => {
    //     const effectData = async () => {
    //         id && await getStatusList();
    //     }
    //     effectData();
    // }, [id]);

    const getStatusList = async () => {
        try {
            appDispatch({ type: "startLoader" });
            const result = await ApiCall("authority/GetStatus", "Get", null);
            if (result?.status === 200)
                setStatusList(result?.data?.data);
            console.log(result?.data?.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            appDispatch({ type: "stopLoader" });
        }
    }

    const getDetail = async () => {
        try {
            appDispatch({ type: "startLoader" });
            await getStatusList();
            const result = await ApiCall("authority/GetRequestDetail?authorityRequestId=" + id, "Get", null);
            if (result?.status === 200)
                setDetail(result?.data?.data);
            console.log(result?.data?.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            appDispatch({ type: "stopLoader" });
        }
    }

    const renderDocument = (doc) => {
        const fileType = doc.name.split('.').pop().toLowerCase();
        const base64Data = doc.documentBase64;

        if (fileType === 'pdf') {
            return (
                <iframe
                    src={`data:application/pdf;base64,${base64Data}`}
                    title={doc.name}
                    width="100%"
                    height="600"
                    style={{ border: '1px solid #ccc', marginTop: '1rem' }}
                />
            );
        } else if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png') {
            return (
                <img
                    src={`data:image/${fileType};base64,${base64Data}`}
                    alt={doc.name}
                    width="100%"
                    style={{ border: '1px solid #ccc', marginTop: '1rem' }}
                />
            );
        } else {
            return <div>Unsupported file type for preview: {fileType}</div>;
        }
    };

    const changeProcessedAuthority = async () => {
        try {
            appDispatch({ type: "startLoader" });
            const result = await ApiCall("processedAuthority/" + id, "Put", null);
            if (result?.status === 200)
                await getDetail();
            console.log(result?.data?.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            appDispatch({ type: "stopLoader" });
        }
    }

    const linkArray = [
        <DropdownItem key="edit" href="/" active>Edit</DropdownItem>,
        <DropdownItem key="share" href="/">Share</DropdownItem>,
        <DropdownItem key="delete" Elem="button">Delete</DropdownItem>,
    ];

    const downloadFile = (documentBase64, name) => {
        // Create a link element, hide it, direct it towards the blob, and then click it
        const link = document.createElement('a');
        link.href = `data:application/octet-stream;base64,${documentBase64}`;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const changeStatusClick = () => {
        setStatusPopup(true);
    }

    const uploadResponse = async () => {
        try {
            appDispatch({ type: "startLoader" });
            const params = {
                authorityRequestId: id,
                documents: []
            }

            if (typeof files === 'object' && files !== null) {
                for (const key in files) {
                    if (Object.hasOwnProperty.call(files, key)) {
                        const f = files[key];
                        const document = {
                            name: f.name,
                            documentBase64: await fileToBase64(f)
                        };
                        params.documents.push(document);
                    }
                }
            } else {
                console.error("file is not an object.");
            }


            const result = await ApiCall("authority/UploadDocument", "Post", params);
            if (result?.status === 200)
                await getDetail();
            console.log(result?.data?.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            appDispatch({ type: "stopLoader" });
        }
    }

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
        });
    };

    const getDetailList = () => {
        switch (detail?.requestDetail?.recordTypeId) {
            case 1:
            case 2:
            case 4:
                return <>
                    <Grid align="center">
                        <GridCol size={3}>
                            <h1>
                                Detail
                            </h1>
                        </GridCol>
                    </Grid>
                    <Grid align="bottom">
                        <GridCol size={2}>Type</GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.recordType}</GridCol>
                        <GridCol size={2}>Date</GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.recordTypeId !== 2 ? detail?.paxList?.paxlistDate : (detail?.requestDetail?.notoriousStartDate + ' - ' + (detail?.requestDetail?.notoriousEndDate || ""))}</GridCol>
                    </Grid>
                    <Grid align="bottom">
                        <GridCol size={2}>Internal Number</GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.authorityInternalNumber}</GridCol>
                        <GridCol size={2}>Request Number</GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.authorityRequestNumber}</GridCol>
                    </Grid>
                    <Grid align="bottom">
                        <GridCol size={2}>Status</GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.status}
                            <Button appearance="primary" display="square" aria-label="Open Settings" onClick={() => changeStatusClick()}>
                                <Icon InlineIcon={IconSettingsSolid} aria-hidden="true" />
                            </Button>
                        </GridCol>
                        <GridCol size={2}>Create Date </GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.createDate}</GridCol>
                    </Grid>
                    <Grid align="bottom">
                        <GridCol size={2}>Country </GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.country}</GridCol>
                        <GridCol size={2}>Station</GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.city || ' - '}</GridCol>
                    </Grid>
                    <Grid align="bottom">
                        <GridCol size={2}>Username</GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.username}</GridCol>
                        <GridCol size={2}>Processed Authority </GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.processedAuthority ? "DONE" : "-"}</GridCol>
                    </Grid>
                    <Grid align="bottom">
                        <GridCol size={2}>Urgent</GridCol>
                        <GridCol size={4}>: {detail?.requestDetail?.urgent ? "True" : "False"}</GridCol>
                    </Grid>
                    {detail?.notorious?.length > 0 ?
                        <Grid align="bottom" extraClasses="flix-space-1-top">
                            <GridCol size={12}>
                                <Accordion
                                    open={true}
                                    title={(
                                        <>
                                            Individual List
                                            <span style={{ float: 'right', fontWeight: 'bold' }}>
                                                Date / Date Range: <span style={{ fontWeight: 'normal' }}>
                                                    {detail?.requestDetail.notoriousStartDate} {detail?.requestDetail.notoriousEndDate ? (" - " + detail?.requestDetail.notoriousEndDate) : ""}
                                                </span>
                                            </span>
                                        </>
                                    )}
                                    key="accordionExample1"
                                >

                                    {/* Table header */}
                                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '8px', border: '1px solid #ddd' }}>#</th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd' }}>First Name</th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Last Name</th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Email</th>
                                                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Phone Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detail.notorious.map((individual, index) => (
                                                <tr key={individual.id}>
                                                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{index + 1}</td>
                                                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{individual.firstName}</td>
                                                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{individual.lastName}</td>
                                                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{individual.email || '-'}</td>
                                                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{individual.phoneNumber || '-'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </Accordion>
                            </GridCol >
                        </Grid>
                        : ""
                    }
                    <Grid align="bottom" extraClasses="flix-space-1-top">
                        <GridCol size={12}>
                            <Accordion
                                // open={true}
                                title={(
                                    <>
                                        Documents
                                    </>
                                )}
                                key="accordionExample1"
                            >
                                {detail?.documents && detail?.documents?.filter(doc => doc?.type === 1)?.length > 0 ? (
                                    detail?.documents.filter(document => document?.type === 1).map((doc, index) => ( // type=1 for official documents
                                        <div key={index} style={{ marginBottom: '20px' }}>
                                            <Grid align="center">
                                                <GridCol size={2}><strong>Document Name :</strong></GridCol>
                                                <GridCol size={10}>
                                                    <ButtonGroup alignment="center" orientation="vertical" style={{ display: 'block' }}>
                                                        {doc.name}
                                                        <Button display="square" appearance="primary" aria-label="New Request" style={{ color: 'var(--hcr-header-color)', marginLeft: '1rem' }} onClick={(e) => downloadFile(doc.documentBase64, doc.name)}>
                                                            <Icon InlineIcon={IconDownload} />
                                                        </Button>
                                                    </ButtonGroup>
                                                </GridCol>
                                            </Grid>
                                            <>
                                                {renderDocument(doc)}
                                            </>
                                        </div>
                                    ))
                                ) : (
                                    <div>No document uploaded.</div>
                                )}
                            </Accordion>
                        </GridCol >
                    </Grid>
                    <Grid align="center">
                        <GridCol size={3}>
                            <h3>
                                Flix Response
                            </h3>
                        </GridCol>
                    </Grid>
                    <Grid align="bottom">
                        <GridCol size={2}>

                            {/* <input
                                required
                                type="file"
                                id="file"
                                name="file"
                                multiple
                                onChange={(e) => setFile(e.target.files)} /> */}

                            {files?.length > 0 && files.length + " file selected"}
                            <input
                                required
                                type="file" id="file" name="file"
                                ref={fileInputRef}
                                multiple
                                onChange={handleFileChange}
                            />
                            <Button display='block' appearance="" onClick={handleButtonClick} name="file">
                                <Icon InlineIcon={IconUpload} /> Choose a file
                            </Button>

                        </GridCol>
                        <GridCol size={2}>
                            <Button display="square" disabled={!files} appearance="primary" aria-label="New Request" style={{ color: 'var(--hcr-header-color)', marginLeft: '1rem' }} onClick={uploadResponse}>
                                <Icon InlineIcon={IconSave} />
                            </Button>
                        </GridCol>
                    </Grid>
                    <Grid align="bottom" extraClasses="flix-space-2-top">
                        <GridCol size={12}>
                            <Accordion
                                // open={true}
                                title={(
                                    <>
                                        Responses
                                    </>
                                )}
                                key="accordionExample1"
                            >
                                {detail?.documents && detail?.documents?.filter(doc => doc?.type === 2)?.length > 0 ? (
                                    detail?.documents.filter(document => document?.type === 2).map((doc, index) => ( // type=2 for flix response
                                        <div key={index} style={{ marginBottom: '20px' }}>
                                            <Grid align="center">
                                                <GridCol size={2}><strong>Document Name :</strong></GridCol>
                                                <GridCol size={10}>
                                                    <ButtonGroup alignment="center" orientation="vertical" style={{ display: 'block' }}>
                                                        {doc.name}
                                                        <Button display="square" appearance="primary" aria-label="New Request" style={{ color: 'var(--hcr-header-color)', marginLeft: '1rem' }} onClick={(e) => downloadFile(doc.documentBase64, doc.name)}>
                                                            <Icon InlineIcon={IconDownload} />
                                                        </Button>
                                                    </ButtonGroup>
                                                </GridCol>
                                            </Grid>
                                            <>
                                                {renderDocument(doc)}
                                            </>
                                        </div>
                                    ))
                                ) : (
                                    <div>No document uploaded.</div>
                                )}
                            </Accordion>
                        </GridCol>
                    </Grid>
                    <StatusChangePopup popupActive={statusPopup} setPopupActive={setStatusPopup} id={id} data={detail} status={statusList} />
                </>
            default:
                return <>
                    No data found.
                </>
        }
    }


    const answers = () => {
        <Grid align="bottom">
            <GridCol size={12}>
                <Accordion
                    title={(
                        <>
                            Documents
                        </>
                    )}
                    key="accordionExample1"
                >
                    {detail?.documents && detail?.documents?.filter(doc => doc?.type === 1)?.length > 0 ? (
                        detail?.documents.filter(document => document?.type === 1).map((doc, index) => ( // type=1 for official documents
                            <div key={index} style={{ marginBottom: '20px' }}>
                                <Grid align="center">
                                    <GridCol size={2}><strong>Document Name :</strong></GridCol>
                                    <GridCol size={10}>
                                        <ButtonGroup alignment="center" orientation="vertical" style={{ display: 'block' }}>
                                            {doc.name}
                                            <Button display="square" appearance="primary" aria-label="New Request" style={{ color: 'var(--hcr-header-color)', marginLeft: '1rem' }} onClick={(e) => downloadFile(doc.documentBase64, doc.name)}>
                                                <Icon InlineIcon={IconDownload} />
                                            </Button>
                                        </ButtonGroup>
                                    </GridCol>
                                </Grid>
                                <>
                                    {renderDocument(doc)}
                                </>
                            </div>
                        ))
                    ) : (
                        <div>No documents available.</div>
                    )}
                </Accordion>
            </GridCol >
        </Grid >
    }
    return (
        <>
            {
                detail && getDetailList()
            }
        </>
    );
}

export default RequestDetail;