import React from 'react';
import Bus from './bus';
import './road.scss';

const Van = () => (
  <svg className="auto van" width="52" height="24" viewBox="0 0 52 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="color-1" d="M51.2805 21.308H26.6545V3.98926H32.4908C33.2025 3.98926 33.9142 4.32138 34.3887 4.89076L38.754 10.3948L48.0539 11.8183L48.2437 11.8657C49.2876 12.0081 50.1417 12.8622 50.284 13.9535V14.0958L51.2805 21.308Z" />
    <path className="color-3" d="M4.30621 16.563H0.225647L1.50674 18.2711H4.21128C4.21128 18.3186 3.59448 17.512 4.30621 16.563Z" />
    <path className="color-1" d="M2.21851 21.3079H26.6545V0.762646C26.6545 0.477954 26.4173 0.240723 26.1326 0.240723H2.74043C2.45574 0.240723 2.21851 0.477954 2.21851 0.762646V21.3079Z" />
    <path className="highlights" d="M36.9509 10.7744C36.9509 10.7744 33.582 5.69738 33.155 5.60248C32.7279 5.50758 29.1693 5.60248 29.1693 5.60248V10.7744H36.9509Z" />
    <path className="color-2" d="M42.36 14.9023H2.21851V16.7528H42.36V14.9023Z" />
    <path className="color-2" d="M44.6376 17.9866H2.21851V19.837H44.6376V17.9866Z" />
    <path className="tires" d="M38.9912 19.2677C38.9912 21.7825 41.0315 23.8228 43.5463 23.8228C46.061 23.8228 48.1013 21.7825 48.1013 19.2677C48.1013 16.7529 46.061 14.7126 43.5463 14.7126C41.0315 14.7126 38.9912 16.7529 38.9912 19.2677Z" />
    <path className="highlights" d="M40.8891 19.2676C40.8891 20.6911 42.0753 21.8773 43.4988 21.8773C44.9223 21.8773 46.1085 20.6911 46.1085 19.2676C46.1085 17.8442 44.9223 16.658 43.4988 16.658C42.0753 16.658 40.8891 17.8442 40.8891 19.2676Z" />
    <path className="tires" d="M6.58374 19.2677C6.58374 21.7825 8.62408 23.8228 11.1389 23.8228C13.6536 23.8228 15.6939 21.7825 15.6939 19.2677C15.6939 16.7529 13.6536 14.7126 11.1389 14.7126C8.62408 14.7126 6.58374 16.7529 6.58374 19.2677Z" />
    <path className="highlights" d="M8.52917 19.2676C8.52917 20.6911 9.71539 21.8773 11.1389 21.8773C12.5623 21.8773 13.7485 20.6911 13.7485 19.2676C13.7485 17.8442 12.5623 16.658 11.1389 16.658C9.71539 16.658 8.52917 17.8442 8.52917 19.2676Z" />
    <path className="red-lights" d="M3.83173 11.3438H1.50677V13.8111H3.83173V11.3438Z" />
    <path className="highlights" d="M50.284 14.0482C50.0468 14.238 49.7146 14.3329 49.3824 14.3329C48.4809 14.3329 47.7692 13.6211 47.7692 12.7196C47.7692 12.3875 47.8641 12.1028 48.0538 11.8181L48.2437 11.8656C49.2875 12.0079 50.1416 12.862 50.284 13.9533V14.0482Z" />
  </svg>
);

const Car1 = () => (
  <svg className="auto car-1" width="44" height="17" viewBox="0 0 44 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="color-1" d="M12.4091 4.81452C12.4091 4.81452 16.7269 1.44566 19.0519 0.639033C21.3769 -0.167596 29.7278 -0.547186 34.3304 1.58801C38.9329 3.77065 41.5426 6.238 42.1119 7.13952C42.6813 8.0885 42.6813 8.13592 43.1084 8.42062C43.5354 8.70531 43.7726 8.70533 43.7726 9.27472C43.8201 9.79665 43.6778 11.4573 43.5829 11.742C43.488 12.0267 42.3018 13.0706 42.0171 13.213C41.7324 13.3553 39.4074 13.8772 38.2686 13.8772C37.1298 13.8772 10.9856 13.8772 9.84682 13.8772C8.70805 13.8772 2.91931 13.8772 2.25502 13.8772C1.59074 13.8772 0.404541 13.1655 0.404541 12.9757C0.404541 12.7859 0.404541 10.3186 0.404541 10.0339C0.404541 9.74919 1.5433 9.51195 1.6382 9.08491C1.68564 8.65787 3.06166 4.81452 12.4091 4.81452Z" />
    <path className="color=2" d="M39.9767 13.8772H1.68566C0.973931 13.8772 0.404541 13.3078 0.404541 12.5961V11.4573H43.6777L42.4915 13.1654L39.9767 13.8772Z" />
    <path className="window" d="M11.7922 13.8772C11.8397 13.64 11.8397 13.4027 11.8397 13.118C11.8397 10.8405 9.98917 8.98999 7.71163 8.98999C5.43409 8.98999 3.58362 10.8405 3.58362 13.118C3.58362 13.3553 3.63106 13.5925 3.63106 13.8772C5.6239 13.8772 8.89786 13.8772 9.79939 13.8772C9.98918 13.8772 10.7009 13.8772 11.7922 13.8772Z" />
    <path className="window" d="M40.7359 13.5451C40.7359 13.4027 40.7359 13.2604 40.7359 13.118C40.7359 10.8405 38.8854 8.98999 36.6079 8.98999C34.3304 8.98999 32.4799 10.8405 32.4799 13.118C32.4799 13.3553 32.5273 13.5925 32.5273 13.8772C35.6589 13.8772 37.889 13.8772 38.2212 13.8772C38.9329 13.8772 39.9293 13.7349 40.7359 13.5451Z" />
    <path className="tires" d="M7.75915 16.5818C9.64592 16.5818 11.1755 15.0523 11.1755 13.1655C11.1755 11.2788 9.64592 9.74927 7.75915 9.74927C5.87237 9.74927 4.34283 11.2788 4.34283 13.1655C4.34283 15.0523 5.87237 16.5818 7.75915 16.5818Z" />
    <path className="highlights" d="M10.0841 13.1655C10.0841 14.4467 9.04025 15.4905 7.75914 15.4905C6.47803 15.4905 5.43414 14.4467 5.43414 13.1655C5.43414 11.8844 6.47803 10.8406 7.75914 10.8406C9.04025 10.8406 10.0841 11.8844 10.0841 13.1655Z" />
    <path className="tires" d="M36.6554 16.5818C38.5421 16.5818 40.0717 15.0523 40.0717 13.1655C40.0717 11.2788 38.5421 9.74927 36.6554 9.74927C34.7686 9.74927 33.2391 11.2788 33.2391 13.1655C33.2391 15.0523 34.7686 16.5818 36.6554 16.5818Z" />
    <path className="highlights" d="M38.9803 13.1655C38.9803 14.4467 37.9365 15.4905 36.6554 15.4905C35.3742 15.4905 34.3304 14.4467 34.3304 13.1655C34.3304 11.8844 35.3742 10.8406 36.6554 10.8406C37.9365 10.8406 38.9803 11.8844 38.9803 13.1655Z" />
    <path className="red-lights" d="M42.0645 8.13586C41.8273 8.51545 41.0681 8.468 40.3564 8.04096C39.6446 7.61392 39.2176 6.99708 39.4548 6.61749C39.6921 6.2379 40.4513 6.28536 41.163 6.7124C41.8747 7.13944 42.3018 7.80372 42.0645 8.13586Z" />
    <path className="highlights" d="M2.20762 9.41703C2.44487 9.70172 3.10915 9.55938 3.72598 9.03745C4.29537 8.51551 4.58007 7.89866 4.29537 7.56652C4.05813 7.28183 3.39384 7.42417 2.77701 7.9461C2.25508 8.46804 1.97038 9.13234 2.20762 9.41703Z" />
    <path className="window" d="M2.16021 12.3588L2.25508 12.1216C2.39743 11.7894 2.20764 11.4573 1.8755 11.4573H0.357117C0.357117 12.169 0.357117 12.8807 0.357117 12.9756H1.21122C1.63827 12.9756 2.01786 12.691 2.16021 12.3588Z" />
    <path className="window" d="M27.0233 7.0447C27.0233 7.13961 26.5013 7.2345 25.8371 7.2345C25.1728 7.2345 24.6508 7.13961 24.6508 7.0447C24.6508 6.94979 25.1728 6.85487 25.8371 6.85487C26.5013 6.80742 27.0233 6.90234 27.0233 7.0447Z" />
    <path className="window" d="M16.205 5.4313H26.6437C26.9284 5.4313 27.1182 5.19406 27.1182 4.95682V1.73034C27.1182 1.44565 26.881 1.25586 26.6437 1.25586H21.8988C20.7126 1.25586 19.5738 1.63546 18.6249 2.39463L15.9203 4.57724C15.5407 4.81448 15.7305 5.4313 16.205 5.4313Z" />
    <path className="window" d="M28.162 1.73042V4.90949C28.162 5.19418 28.3993 5.38397 28.6365 5.38397H36.2283C36.6554 5.38397 36.8926 4.90948 36.6079 4.57734C35.8962 3.72326 34.5202 2.4896 32.5273 1.82532C31.2937 1.39828 29.5381 1.25594 28.6365 1.2085C28.3993 1.2085 28.162 1.44573 28.162 1.73042Z" />
  </svg>
);

const Car2 = () => (
  <svg className="auto car-2" width="41" height="18" viewBox="0 0 41 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="color-2" d="M11.096 5.90953C11.096 5.90953 6.3986 6.4789 4.92769 6.90594C3.45678 7.33298 1.13176 8.0447 0.799621 10.8442C0.799621 10.8442 0.325134 11.0814 0.325134 11.5085C0.325134 11.8881 0.372583 13.2641 0.799621 14.308H38.5688L40.5142 12.5049C40.5142 12.5049 40.704 12.1253 40.704 11.7931C40.704 11.461 40.6566 10.5121 40.6091 10.4172C40.5617 10.3223 40.2295 9.99012 39.755 9.70542C39.755 9.70542 39.8974 8.75645 39.6601 7.80747C39.4229 6.8585 39.0433 5.86206 38.1892 5.86206H11.096V5.90953Z" />
    <path className="color-2" d="M1.41646 9.0886C1.41646 9.13605 1.36902 9.18347 1.36902 9.18347H3.59909C3.78888 9.18347 4.5006 8.94623 5.06999 8.70898C5.63937 8.42429 9.24549 7.2381 10.2419 6.9534C11.0485 6.76361 11.6654 6.2891 11.9975 5.86206H11.8552C11.523 6.24165 10.9062 6.66869 10.1945 6.81104C9.19805 7.04828 5.5445 8.28195 4.97511 8.56664C4.40573 8.85134 3.74145 9.04113 3.55165 9.04113H1.41646V9.0886Z" />
    <path className="color-3" d="M26.6592 14.3553C26.8964 13.5012 27.4658 11.1288 27.4658 9.46808C27.4658 7.61758 27.276 6.52626 26.3745 5.90942H26.1847C27.1811 6.47881 27.3709 7.57013 27.4183 9.46808C27.4183 11.0813 26.849 13.5012 26.6117 14.3078H26.6592V14.3553Z" />
    <path className="color-3" d="M12.5669 7.71247C12.7093 6.6686 13.6582 6.14667 14.2751 5.90942H13.9904C13.3736 6.19412 12.6143 6.71606 12.472 7.66503C12.3296 8.56656 11.9501 11.6982 12.5669 14.3553H12.6618C12.045 11.7456 12.4246 8.61399 12.5669 7.71247Z" />
    <path className="window" d="M40.182 12.315C39.9922 12.315 39.66 12.315 39.4228 11.8405C38.6636 10.4645 37.1927 9.56299 35.532 9.56299C33.2545 9.56299 31.404 11.2237 31.0719 13.4063C30.977 13.8334 30.6448 14.3079 30.2652 14.3079C29.8856 14.3079 12.3296 14.3079 11.523 14.3079C10.7164 14.3079 10.6215 13.4063 10.6215 13.4063C10.2894 11.2237 8.43886 9.56299 6.16133 9.56299C3.64655 9.56299 1.65371 11.6033 1.65371 14.0706C1.65371 14.1181 1.65371 14.1655 1.65371 14.2604C1.46391 14.2604 1.27411 14.2604 1.22666 14.2604C1.08431 14.2604 0.847046 14.0706 0.847046 14.0706C0.847046 14.0706 0.847046 14.5925 0.847046 14.7823C0.847046 14.9721 1.41645 15.5415 1.93839 15.5415H2.08076H10.0521H10.4791H31.3565H32.1631H37.9993C37.9993 15.5415 39.0907 15.3043 39.7075 14.8298C39.7075 14.8298 40.3718 14.4027 40.4667 14.1655C40.5616 13.9282 40.7039 13.5012 40.7514 13.3114C40.7988 13.1216 40.7988 12.315 40.7988 12.315C40.7988 12.315 40.3718 12.315 40.182 12.315Z" />
    <path className="tires" d="M6.11386 17.5818C8.02687 17.5818 9.57764 16.031 9.57764 14.118C9.57764 12.2051 8.02687 10.6543 6.11386 10.6543C4.20085 10.6543 2.65002 12.2051 2.65002 14.118C2.65002 16.031 4.20085 17.5818 6.11386 17.5818Z" />
    <path className="highlights" d="M7.79877 15.7977C8.72525 14.8712 8.72525 13.3691 7.79877 12.4426C6.87228 11.5161 5.37015 11.5161 4.44366 12.4426C3.51718 13.3691 3.51718 14.8712 4.44366 15.7977C5.37015 16.7241 6.87228 16.7241 7.79877 15.7977Z" />
    <path className="tires" d="M35.5321 17.5818C37.4451 17.5818 38.9958 16.031 38.9958 14.118C38.9958 12.2051 37.4451 10.6543 35.5321 10.6543C33.6191 10.6543 32.0684 12.2051 32.0684 14.118C32.0684 16.031 33.6191 17.5818 35.5321 17.5818Z" />
    <path className="highlights" d="M37.2116 15.7857C38.1381 14.8592 38.1381 13.3571 37.2116 12.4306C36.2851 11.5041 34.783 11.5041 33.8566 12.4306C32.9301 13.3571 32.9301 14.8592 33.8566 15.7857C34.783 16.7122 36.2851 16.7122 37.2116 15.7857Z" />
    <path className="highlights" d="M3.12458 9.37334C2.69757 10.1325 1.98588 10.7494 1.51143 10.7494C1.03697 10.7494 0.989549 10.1325 1.41656 9.37334C1.84357 8.61416 2.55526 7.99731 3.02971 7.99731C3.50417 7.99731 3.55159 8.61416 3.12458 9.37334Z" />
    <path className="window" d="M11.096 5.90948C11.096 5.90948 11.096 6.52631 11.7128 6.52631C12.3296 6.52631 38.2366 5.90948 38.2366 5.90948C38.2366 5.90948 38.1892 5.67223 37.952 5.34009C37.7147 5.00795 35.5795 1.68654 35.105 1.59164C34.6305 1.49674 27.6082 1.25952 25.8051 1.25952C24.0021 1.25952 16.9322 1.49676 16.4577 1.6391C15.9832 1.78145 12.8042 4.10643 11.096 5.90948Z" />
    <path className="detail" d="M27.3234 6.19423L26.7066 1.49683L25.9 1.5917L25.4255 6.2417C26.0898 6.19425 26.7066 6.19423 27.3234 6.19423Z" />
    <path className="detail" d="M14.3699 6.47893C15.0816 5.43506 17.1219 2.58813 18.4505 1.63916L16.9321 1.7815C16.9321 1.7815 14.1327 4.53352 12.5195 6.52637C12.9465 6.52637 13.5633 6.47893 14.3699 6.47893Z" />
    <path className="detail" d="M37.952 5.38754C37.7148 5.05539 35.5795 1.73399 35.105 1.63909C34.9627 1.59164 34.2984 1.59164 33.3969 1.54419L33.0173 1.59163L35.7219 6.00439C37.2877 5.95695 38.2841 5.95693 38.2841 5.95693C38.2841 5.95693 38.1892 5.67223 37.952 5.38754Z" />
    <path className="window" d="M24.524 8.51926C24.9695 8.51926 25.3307 8.15811 25.3307 7.71263C25.3307 7.26715 24.9695 6.90601 24.524 6.90601C24.0785 6.90601 23.7173 7.26715 23.7173 7.71263C23.7173 8.15811 24.0785 8.51926 24.524 8.51926Z" />
    <path className="color-1" d="M24.524 7.94983C25.2839 7.94983 25.9 7.84361 25.9 7.71259C25.9 7.58156 25.2839 7.47534 24.524 7.47534C23.764 7.47534 23.1479 7.58156 23.1479 7.71259C23.1479 7.84361 23.764 7.94983 24.524 7.94983Z" />
    <path className="red-lights" d="M39.6127 9.18346C39.6601 9.56305 39.4229 9.84775 39.0908 9.84775C38.7112 9.84775 38.379 9.56305 38.3316 9.18346L38.0469 7.47533C37.9994 7.09574 38.2367 6.81104 38.5688 6.81104C38.9484 6.81104 39.2805 7.09574 39.328 7.47533L39.6127 9.18346Z" />
    <path className="color-2" d="M35.8642 1.92383H16.3154C16.0781 1.92383 15.8883 1.73405 15.8883 1.49681V1.4019C15.8883 1.16466 16.0781 0.974854 16.3154 0.974854H35.8642C36.1015 0.974854 36.2913 1.16466 36.2913 1.4019V1.49681C36.2438 1.73405 36.054 1.92383 35.8642 1.92383Z" />
  </svg>
);

const Street = () => (
  <svg className="street" width="1366" height="42" viewBox="0 0 1366 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="color-1" d="M948.5,23.3H0v5.3h948.5V23.3z" />
    <path className="color-1" d="M1366,23.3h-303v5.3h303V23.3z" />
    <path className="color-2" d="M948.5,41.2H12.7C5.7,41.2,0,35.6,0,28.6c0,0,923.5,0,933.6,0c10,0,14.9-4.9,14.9-4.9V41.2z" />
    <path className="color-2" d="M1063,41.2h295.4c4.2,0,7.6-3.4,7.6-7.6v-5.1c0,0-278.1,0-288.1,0c-10,0-14.9-4.9-14.9-4.9V41.2z" />
  </svg>
);

const Bridge = () => (
  <svg className="bridge" width="1366" height="42" viewBox="0 0 1366 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="bridge-base" d="M1063.1,23.7v17.6h-26.4c-1.3,0-2.5-0.9-2.9-2.2c-3.7-12-14.9-10.4-28.1-10.4c-13.2,0-24.3-1.6-28,10.4c-0.4,1.3-1.6,2.2-2.9,2.2h-26.4V23.7c0,0,22.2-17.6,57.3-17.6C1040.9,6.1,1063.1,23.7,1063.1,23.7z" />
    <path className="bridge-rails" d="M1063.3,19.3c-0.2-0.2-22.6-17.4-57.5-17.4c-34.9,0-57.3,17.3-57.5,17.4l-0.6-0.8c0.2-0.2,22.9-17.7,58.1-17.7c35.2,0,57.9,17.5,58.1,17.7L1063.3,19.3z" />
    <path className="bridge-brick" d="M953.3,26.6h-4.9V29h4.9V26.6z" />
    <path className="bridge-brick" d="M963.5,26.6h-4.9V29h4.9V26.6z" />
    <path className="bridge-brick" d="M998.1,10.4h-4.9v2.3h4.9V10.4z" />
    <path className="bridge-brick" d="M1018.2,14h-4.9v2.3h4.9V14z" />
    <path className="bridge-brick" d="M959.2,31.3h-4.9v2.3h4.9V31.3z" />
    <path className="bridge-brick" d="M1057.9,33.6h4.9v-2.3h-4.9V33.6z" />
    <path className="bridge-brick" d="M1047.7,33.6h4.9v-2.3h-4.9V33.6z" />
    <path className="bridge-brick" d="M1052.1,29h4.9v-2.3h-4.9V29z" />
    <path className="bridge-pole" d="M947.2,15.2c-0.7,0-1.2,0.6-1.2,1.2v9.1l2.4-1.8v-7.3C948.5,15.7,947.9,15.2,947.2,15.2z" />
    <path className="bridge-pole" d="M1064.3,15.2c-0.7,0-1.2,0.6-1.2,1.2v7.3l2.4,1.8v-9.1C1065.6,15.7,1065,15.2,1064.3,15.2z" />
    <path className="color-2" d="M1006.2,1.4h-0.9v4.7h0.9V1.4z" />
    <path className="color-2" d="M1040.6,7.2h-1v4.7h1V7.2z" />
    <path className="color-2" d="M973.5,6.8h-0.9v4.7h0.9V6.8z" />
  </svg>
);

const Road = () => (
  <div className="landscape__road">
    <Street />
    <Car1 />
    <Van />
    <Car2 />
    <Bus />
    <Bridge />
  </div>
);

export default Road;
