import React from 'react';
import flixtrain from '../../../images/flixtrain.png';
import './railroad.scss';

const Locomotive = () => (
  <div className="locomotive">
    <svg width="125" height="41" viewBox="0 0 125 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="train-color" d="M115.361 7.39518C111.85 4.59571 104.068 3.50439 104.068 3.50439L98.564 4.54826H28.5298L23.0258 3.50439C23.0258 3.50439 15.2442 4.59571 11.733 7.39518C3.33456 14.0854 2.10088 29.3165 2.10088 29.3165H124.993C124.993 29.3165 123.759 14.0854 115.361 7.39518Z" />
      <path className="train-steel-2" d="M69.4305 35.9595H118.445V32.0212H71.1862L69.4305 35.9595Z" />
      <path className="train-steel-2" d="M78.256 40.989C81.5055 40.989 84.1396 38.3547 84.1396 35.1053C84.1396 31.8559 81.5055 29.2217 78.256 29.2217C75.0066 29.2217 72.3724 31.8559 72.3724 35.1053C72.3724 38.3547 75.0066 40.989 78.256 40.989Z" />
      <path className="train-steel-3" d="M78.256 39.2334C80.5359 39.2334 82.384 37.3852 82.384 35.1053C82.384 32.8255 80.5359 30.9773 78.256 30.9773C75.9761 30.9773 74.1279 32.8255 74.1279 35.1053C74.1279 37.3852 75.9761 39.2334 78.256 39.2334Z" />
      <path className="train-steel-2" d="M103.451 40.989C106.701 40.989 109.335 38.3547 109.335 35.1053C109.335 31.8559 106.701 29.2217 103.451 29.2217C100.202 29.2217 97.5675 31.8559 97.5675 35.1053C97.5675 38.3547 100.202 40.989 103.451 40.989Z" />
      <path className="train-steel-3" d="M103.451 39.2334C105.731 39.2334 107.579 37.3852 107.579 35.1053C107.579 32.8255 105.731 30.9773 103.451 30.9773C101.171 30.9773 99.3231 32.8255 99.3231 35.1053C99.3231 37.3852 101.171 39.2334 103.451 39.2334Z" />
      <path className="train-steel-5" opacity="0.4" d="M72.6571 33.3972L72.3724 35.9594H84.0447L83.8549 33.3972H72.6571Z" />
      <path className="train-steel-2" d="M10.4519 35.9595H57.948V32.0212H12.2548L10.4519 35.9595Z" />
      <path className="train-steel-2" d="M19.2772 40.989C22.5267 40.989 25.1609 38.3547 25.1609 35.1053C25.1609 31.8559 22.5267 29.2217 19.2772 29.2217C16.0278 29.2217 13.3937 31.8559 13.3937 35.1053C13.3937 38.3547 16.0278 40.989 19.2772 40.989Z" />
      <path className="train-steel-3" d="M19.2773 39.2334C21.5571 39.2334 23.4053 37.3852 23.4053 35.1053C23.4053 32.8255 21.5571 30.9773 19.2773 30.9773C16.9974 30.9773 15.1492 32.8255 15.1492 35.1053C15.1492 37.3852 16.9974 39.2334 19.2773 39.2334Z" />
      <path className="train-steel-2" d="M44.4725 40.989C47.7219 40.989 50.3561 38.3547 50.3561 35.1053C50.3561 31.8559 47.7219 29.2217 44.4725 29.2217C41.2231 29.2217 38.5889 31.8559 38.5889 35.1053C38.5889 38.3547 41.2231 40.989 44.4725 40.989Z" />
      <path className="train-steel-3" d="M44.4726 39.2334C46.7524 39.2334 48.6006 37.3852 48.6006 35.1053C48.6006 32.8255 46.7524 30.9773 44.4726 30.9773C42.1927 30.9773 40.3446 32.8255 40.3446 35.1053C40.3446 37.3852 42.1927 39.2334 44.4726 39.2334Z" />
      <path className="train-steel-1" d="M117.733 36.8136H123.949L124.993 29.3167H100.13L96.8084 27.2764H30.9497L27.4859 29.3167H2.14836L3.19221 36.8136H9.40791L13.4411 33.3973H114.317L117.733 36.8136Z" />
      <path className="train-color-shade" d="M25.588 12.4248H20.1314C19.562 12.4248 19.0875 12.8993 19.0875 13.4687V24.7615C19.0875 25.3309 19.562 25.8053 20.1314 25.8053H25.588C26.1574 25.8053 26.6318 25.3309 26.6318 24.7615V13.4687C26.6318 12.8993 26.1574 12.4248 25.588 12.4248ZM26.2997 24.7615C26.2997 25.1411 26.015 25.4732 25.588 25.4732H20.1314C19.7518 25.4732 19.4196 25.1885 19.4196 24.7615V13.4687C19.4196 13.0891 19.7043 12.757 20.1314 12.757H25.588C25.9676 12.757 26.2997 13.0416 26.2997 13.4687V24.7615Z" />
      <path className="window" d="M23.358 18.5457H22.3615C21.6497 18.5457 21.0804 17.9763 21.0804 17.2646V15.1769C21.0804 14.4651 21.6497 13.8958 22.3615 13.8958H23.358C24.0697 13.8958 24.6391 14.4651 24.6391 15.1769V17.2646C24.6865 17.9763 24.0697 18.5457 23.358 18.5457Z" />
      <path className="train-steel-4" d="M25.161 20.8232H24.2594C24.0696 20.8232 23.9273 20.6808 23.9273 20.491V20.3961C23.9273 20.2063 24.0696 20.064 24.2594 20.064H25.161C25.3508 20.064 25.4931 20.2063 25.4931 20.3961V20.491C25.4931 20.6808 25.3508 20.8232 25.161 20.8232Z" />
      <path className="train-steel-4" d="M27.818 25.7105H27.7706C27.6757 25.7105 27.5807 25.6156 27.5807 25.5207V18.5457C27.5807 18.4509 27.6757 18.356 27.7706 18.356H27.818C27.9129 18.356 28.0079 18.4509 28.0079 18.5457V25.5207C28.0079 25.6156 27.9129 25.7105 27.818 25.7105Z" />
      <path className="train-steel-4" d="M17.9962 25.7105H17.9487C17.8538 25.7105 17.7589 25.6156 17.7589 25.5207V18.5457C17.7589 18.4509 17.8538 18.356 17.9487 18.356H17.9962C18.0912 18.356 18.1861 18.4509 18.1861 18.5457V25.5207C18.2335 25.6156 18.1386 25.7105 17.9962 25.7105Z" />
      <path className="window" d="M15.1017 17.4545H8.83855C8.45896 17.4545 8.22172 17.0749 8.36407 16.7428L9.83502 13.2316C9.97736 12.852 10.357 12.6147 10.784 12.6147H15.0544C15.6237 12.6147 16.0982 13.0892 16.0982 13.6586V16.4581C16.1457 17.0275 15.6711 17.4545 15.1017 17.4545Z" />
      <path className="train-steel-3" d="M12.3971 12.6147H12.065V17.4545H12.3971V12.6147Z" />
      <path className="train-color-shade" d="M106.725 12.4248H101.269C100.699 12.4248 100.225 12.8993 100.225 13.4687V24.7615C100.225 25.3309 100.699 25.8053 101.269 25.8053H106.725C107.295 25.8053 107.769 25.3309 107.769 24.7615V13.4687C107.769 12.8993 107.295 12.4248 106.725 12.4248ZM107.437 24.7615C107.437 25.1411 107.152 25.4732 106.725 25.4732H101.269C100.889 25.4732 100.557 25.1885 100.557 24.7615V13.4687C100.557 13.0891 100.842 12.757 101.269 12.757H106.725C107.105 12.757 107.437 13.0416 107.437 13.4687V24.7615Z" />
      <path className="window" d="M104.495 18.5457H103.499C102.787 18.5457 102.218 17.9763 102.218 17.2646V15.1769C102.218 14.4651 102.787 13.8958 103.499 13.8958H104.495C105.207 13.8958 105.776 14.4651 105.776 15.1769V17.2646C105.824 17.9763 105.207 18.5457 104.495 18.5457Z" />
      <path className="train-steel-4" d="M106.298 20.8232H105.397C105.207 20.8232 105.064 20.6808 105.064 20.491V20.3961C105.064 20.2063 105.207 20.064 105.397 20.064H106.298C106.488 20.064 106.63 20.2063 106.63 20.3961V20.491C106.63 20.6808 106.488 20.8232 106.298 20.8232Z" />
      <path className="train-steel-4" d="M108.955 25.7105H108.908C108.813 25.7105 108.718 25.6156 108.718 25.5207V18.5457C108.718 18.4509 108.813 18.356 108.908 18.356H108.955C109.05 18.356 109.145 18.4509 109.145 18.5457V25.5207C109.145 25.6156 109.05 25.7105 108.955 25.7105Z" />
      <path className="train-steel-4" d="M99.1334 25.7105H99.0859C98.991 25.7105 98.8961 25.6156 98.8961 25.5207V18.5457C98.8961 18.4509 98.991 18.356 99.0859 18.356H99.1334C99.2283 18.356 99.3231 18.4509 99.3231 18.5457V25.5207C99.3706 25.6156 99.2757 25.7105 99.1334 25.7105Z" />
      <path className="window" d="M111.897 17.4545H118.16C118.54 17.4545 118.777 17.0749 118.635 16.7428L117.164 13.2316C117.022 12.852 116.642 12.6147 116.215 12.6147H111.945C111.375 12.6147 110.901 13.0892 110.901 13.6586V16.4581C110.853 17.0275 111.328 17.4545 111.897 17.4545Z" />
      <path className="train-steel-3" d="M114.934 12.6147H114.602V17.4545H114.934V12.6147Z" />
      <path className="train-steel-3" d="M47.604 29.3165C47.3193 29.0318 46.8924 28.842 46.4653 28.842C46.0383 28.842 45.6113 29.0318 45.3266 29.3165C45.0419 29.6012 44.8995 29.9808 44.8995 30.4078C44.8995 30.5976 44.947 30.7874 44.9944 30.9772C45.2316 31.5466 45.8011 31.9737 46.4653 31.9737C46.7026 31.9737 46.8924 31.9262 47.0822 31.8313C47.6515 31.5941 48.0311 31.0721 48.0311 30.4078V30.3604C48.0311 29.9808 47.8887 29.6012 47.604 29.3165ZM47.8413 30.4078C47.8413 31.0247 47.4617 31.5466 46.8923 31.7364C46.75 31.7839 46.6077 31.8313 46.4653 31.8313C45.896 31.8313 45.4215 31.4992 45.1843 31.0247C45.0894 30.8349 45.0419 30.6451 45.0419 30.4078C45.0419 29.9808 45.2317 29.6012 45.5164 29.364C45.7536 29.1742 46.0857 29.0318 46.4178 29.0318C46.75 29.0318 47.0822 29.1742 47.3194 29.364C47.6041 29.6012 47.7938 29.9334 47.8413 30.3604C47.8413 30.3604 47.8413 30.3604 47.8413 30.4078Z" />
      <path className="train-steel-3" d="M35.5997 29.3165C35.315 29.0318 34.8879 28.842 34.4608 28.842C34.0338 28.842 33.6068 29.0318 33.3221 29.3165C33.0374 29.6012 32.895 29.9808 32.895 30.4078C32.895 31.2619 33.6068 31.9737 34.4608 31.9737C35.3149 31.9737 36.0267 31.2619 36.0267 30.4078C36.0741 29.9808 35.8844 29.6012 35.5997 29.3165ZM34.4608 31.8313C33.7017 31.8313 33.0374 31.2145 33.0374 30.4078C33.0374 29.9808 33.2272 29.6012 33.5594 29.3165C33.7966 29.1267 34.1287 28.9844 34.4608 28.9844C34.793 28.9844 35.1252 29.1267 35.3624 29.3165C35.6946 29.5538 35.8843 29.9808 35.8843 30.4078C35.8843 31.167 35.2675 31.8313 34.4608 31.8313Z" />
      <path className="train-steel-3" d="M92.5855 29.3165C92.3008 29.0318 91.8738 28.842 91.4468 28.842C91.0197 28.842 90.5926 29.0318 90.308 29.3165C90.0233 29.6012 89.881 29.9808 89.881 30.4078C89.881 31.2619 90.5927 31.9737 91.4468 31.9737C92.3008 31.9737 93.0126 31.2619 93.0126 30.4078C93.0126 29.9808 92.8702 29.6012 92.5855 29.3165ZM91.4468 31.8313C90.6876 31.8313 90.0233 31.2145 90.0233 30.4078C90.0233 29.9808 90.2131 29.6012 90.5452 29.3165C90.7824 29.1267 91.1146 28.9844 91.4468 28.9844C91.7789 28.9844 92.111 29.1267 92.3482 29.3165C92.6804 29.5538 92.8702 29.9808 92.8702 30.4078C92.8702 31.167 92.2059 31.8313 91.4468 31.8313Z" />
      <path className="train-steel-3" d="M80.9607 29.9333C80.8658 29.696 80.7709 29.5062 80.5811 29.3639C80.2963 29.0792 79.8693 28.8894 79.4423 28.8894C79.0152 28.8894 78.6356 29.0317 78.3984 29.3164C78.3509 29.3164 78.3509 29.3639 78.3035 29.4113C78.0188 29.696 77.8763 30.0756 77.8763 30.5027C77.8763 30.6925 77.9238 30.8822 77.9712 31.072C78.2085 31.6889 78.7779 32.0685 79.4423 32.0685C79.8219 32.0685 80.2015 31.9261 80.4388 31.6889C80.771 31.4042 81.0082 30.9771 81.0082 30.5027C81.0556 30.218 81.0081 30.0756 80.9607 29.9333ZM80.3438 31.5465C80.1065 31.7363 79.8219 31.8312 79.4898 31.8312C78.9203 31.8312 78.3983 31.4991 78.2085 30.9771C78.1136 30.7874 78.1136 30.5976 78.1136 30.4078C78.1136 29.9807 78.3035 29.6011 78.6356 29.3164C78.6831 29.3164 78.683 29.269 78.7305 29.269C78.9677 29.1266 79.2524 29.0317 79.5371 29.0317C79.8693 29.0317 80.2015 29.1741 80.4388 29.3639C80.5811 29.5062 80.7234 29.6486 80.8183 29.8384C80.9132 30.0282 80.9607 30.218 80.9607 30.4552C80.8658 30.8822 80.676 31.2618 80.3438 31.5465Z" />
      <path className="train-steel-3" d="M2.3381 34.0139H1.81611C0.867132 34.0139 0.108017 33.2547 0.108017 32.3058V29.7435C0.108017 28.7946 0.867132 28.0354 1.81611 28.0354H2.3381C3.28708 28.0354 4.04631 28.7946 4.04631 29.7435V32.3058C4.04631 33.2547 3.28708 34.0139 2.3381 34.0139Z" />
      <path className="train-steel-2" d="M92.0636 9.57787H33.844C33.3695 9.57787 32.99 9.19828 32.99 8.7238V6.7784C32.99 6.30391 33.3695 5.92432 33.844 5.92432H92.0636C92.5381 5.92432 92.9176 6.30391 92.9176 6.7784V8.7238C92.9176 9.15083 92.5381 9.57787 92.0636 9.57787Z" />
      <path className="train-steel-3" d="M44.5199 6.49365H34.1761V8.91353H44.5199V6.49365Z" />
      <path className="train-steel-3" d="M56.2872 6.49365H45.9434V8.91353H56.2872V6.49365Z" />
      <path className="train-steel-3" d="M68.0546 6.49365H57.7107V8.91353H68.0546V6.49365Z" />
      <path className="train-steel-3" d="M79.8693 6.49365H69.5255V8.91353H79.8693V6.49365Z" />
      <path className="train-steel-3" d="M91.6365 6.49365H81.2927V8.91353H91.6365V6.49365Z" />
      <path className="train-steel-2" d="M54.7215 0.467773H47.3669L47.0348 1.65399H55.0537L54.7215 0.467773Z" />
      <path className="train-steel-1" d="M56.1924 1.65405H46.0384C45.5639 1.65405 45.1843 2.03365 45.1843 2.50813V5.87697H57.0465V2.50813C57.0465 2.03365 56.6669 1.65405 56.1924 1.65405Z" />
      <path className="train-steel-3" d="M55.7654 2.17603H46.4654C46.2282 2.17603 46.0385 2.36582 46.0385 2.60306V4.92807C46.0385 5.16531 46.2282 5.3551 46.4654 5.3551H55.7654C56.0026 5.3551 56.1924 5.16531 56.1924 4.92807V2.60306C56.1924 2.36582 56.0026 2.17603 55.7654 2.17603ZM56.0026 4.92807C56.0026 5.07041 55.9077 5.16531 55.7654 5.16531H46.4654C46.3231 5.16531 46.2282 5.07041 46.2282 4.92807V2.60306C46.2282 2.46072 46.3231 2.36582 46.4654 2.36582H55.7654C55.9077 2.36582 56.0026 2.46072 56.0026 2.60306V4.92807Z" />
      <path className="train-steel-3" d="M56.0974 3.125H46.1333V3.3148H56.0974V3.125Z" />
      <path className="train-steel-3" d="M56.0974 4.26367H46.1333V4.45351H56.0974V4.26367Z" />
      <path className="train-steel-2" d="M78.256 0.467773H70.9489L70.6167 1.65399H78.6356L78.256 0.467773Z" />
      <path className="train-steel-1" d="M79.7743 1.65405H69.6203C69.1458 1.65405 68.7662 2.03365 68.7662 2.50813V5.87697H80.6284V2.50813C80.6284 2.03365 80.2488 1.65405 79.7743 1.65405Z" />
      <path className="train-steel-3" d="M79.2999 2.17603H70C69.7627 2.17603 69.5729 2.36582 69.5729 2.60306V4.92807C69.5729 5.16531 69.7627 5.3551 70 5.3551H79.2999C79.5372 5.3551 79.727 5.16531 79.727 4.92807V2.60306C79.7745 2.36582 79.5372 2.17603 79.2999 2.17603ZM79.5847 4.92807C79.5847 5.07041 79.4898 5.16531 79.3474 5.16531H70.0474C69.905 5.16531 69.8101 5.07041 69.8101 4.92807V2.60306C69.8101 2.46072 69.905 2.36582 70.0474 2.36582H79.3474C79.4898 2.36582 79.5847 2.46072 79.5847 2.60306V4.92807Z" />
      <path className="train-steel-3" d="M79.6794 3.125H69.7152V3.3148H79.6794V3.125Z" />
      <path className="train-steel-3" d="M79.6794 4.26367H69.7152V4.45351H79.6794V4.26367Z" />
      <path className="train-steel-2" d="M70.5693 33.3972H52.6813V39.7553H70.5693V33.3972Z" />
      <path className="train-steel-3" d="M61.7439 33.3972H61.5541V39.7553H61.7439V33.3972Z" />
      <path className="train-steel-3" d="M57.0464 38.9962C57.4395 38.9962 57.7582 38.6776 57.7582 38.2845C57.7582 37.8914 57.4395 37.5728 57.0464 37.5728C56.6534 37.5728 56.3347 37.8914 56.3347 38.2845C56.3347 38.6776 56.6534 38.9962 57.0464 38.9962Z" />
      <path className="train-steel-3" d="M66.631 38.9962C67.0241 38.9962 67.3428 38.6776 67.3428 38.2845C67.3428 37.8914 67.0241 37.5728 66.631 37.5728C66.238 37.5728 65.9193 37.8914 65.9193 38.2845C65.9193 38.6776 66.238 38.9962 66.631 38.9962Z" />
      <path className="train-steel-5" opacity="0.4" d="M13.6783 33.3972L13.441 35.9594H25.066L24.8762 33.3972H13.6783Z" />
      <path className="train-steel-2" d="M39.5378 1.89111H30.1431V4.54823H39.5378V1.89111Z" />
      <path className="train-steel-2" d="M87.5906 4.52759L96.9852 4.52759L96.9852 1.87044L87.5906 1.87044L87.5906 4.52759Z" />
    </svg>
    <img className="flixtrain-lettering" src={flixtrain} alt="" aria-hidden="true" />
  </div>
);

const Wagon = () => (
  <div className="wagon">
    <svg width="152" height="38" viewBox="0 0 152 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="train-steel-2" d="M109.701 37.989C112.951 37.989 115.585 35.3547 115.585 32.1053C115.585 28.8559 112.951 26.2217 109.701 26.2217C106.452 26.2217 103.818 28.8559 103.818 32.1053C103.818 35.3547 106.452 37.989 109.701 37.989Z" />
      <path className="train-steel-3" d="M109.701 36.2334C111.981 36.2334 113.829 34.3852 113.829 32.1053C113.829 29.8255 111.981 27.9773 109.701 27.9773C107.422 27.9773 105.573 29.8255 105.573 32.1053C105.573 34.3852 107.422 36.2334 109.701 36.2334Z" />
      <path className="train-steel-2" d="M134.944 37.989C138.194 37.989 140.828 35.3547 140.828 32.1053C140.828 28.8559 138.194 26.2217 134.944 26.2217C131.695 26.2217 129.061 28.8559 129.061 32.1053C129.061 35.3547 131.695 37.989 134.944 37.989Z" />
      <path className="train-steel-3" d="M134.944 36.2334C137.224 36.2334 139.072 34.3852 139.072 32.1053C139.072 29.8255 137.224 27.9773 134.944 27.9773C132.664 27.9773 130.816 29.8255 130.816 32.1053C130.816 34.3852 132.664 36.2334 134.944 36.2334Z" />
      <path className="train-steel-2" d="M22.8229 37.989C26.0724 37.989 28.7067 35.3547 28.7067 32.1053C28.7067 28.8559 26.0724 26.2217 22.8229 26.2217C19.5735 26.2217 16.9393 28.8559 16.9393 32.1053C16.9393 35.3547 19.5735 37.989 22.8229 37.989Z" />
      <path className="train-steel-3" d="M22.8229 36.2334C25.1028 36.2334 26.951 34.3852 26.951 32.1053C26.951 29.8255 25.1028 27.9773 22.8229 27.9773C20.5431 27.9773 18.6949 29.8255 18.6949 32.1053C18.6949 34.3852 20.5431 36.2334 22.8229 36.2334Z" />
      <path className="train-steel-2" d="M48.0181 37.989C51.2676 37.989 53.9017 35.3547 53.9017 32.1053C53.9017 28.8559 51.2676 26.2217 48.0181 26.2217C44.7687 26.2217 42.1345 28.8559 42.1345 32.1053C42.1345 35.3547 44.7687 37.989 48.0181 37.989Z" />
      <path className="train-steel-3" d="M48.0182 36.2334C50.2981 36.2334 52.1462 34.3852 52.1462 32.1053C52.1462 29.8255 50.2981 27.9773 48.0182 27.9773C45.7383 27.9773 43.8901 29.8255 43.8901 32.1053C43.8901 34.3852 45.7383 36.2334 48.0182 36.2334Z" />
      <path className="train-steel-2" d="M86.1195 30.3972H68.2313V36.7553H86.1195V30.3972Z" />
      <path className="train-steel-3" d="M77.2939 30.3972H77.1042V36.7553H77.2939V30.3972Z" />
      <path className="train-steel-3" d="M72.5966 35.9962C72.9897 35.9962 73.3083 35.6776 73.3083 35.2845C73.3083 34.8914 72.9897 34.5728 72.5966 34.5728C72.2035 34.5728 71.8849 34.8914 71.8849 35.2845C71.8849 35.6776 72.2035 35.9962 72.5966 35.9962Z" />
      <path className="train-steel-3" d="M82.1812 35.9962C82.5743 35.9962 82.8929 35.6776 82.8929 35.2845C82.8929 34.8914 82.5743 34.5728 82.1812 34.5728C81.7881 34.5728 81.4695 34.8914 81.4695 35.2845C81.4695 35.6776 81.7881 35.9962 82.1812 35.9962Z" />
      <path className="train-color" d="M151.219 6.24561H5.64648V29.0684H151.219V6.24561Z" />
      <path className="train-steel-1" d="M151.219 1.07373H5.64648V6.24562H151.219V1.07373Z" />
      <path className="train-steel-4" d="M151.219 29.0686H5.64648V32.1053H151.219V29.0686Z" />
      <path className="train-steel-3" d="M151.219 11.0854H5.64648V21.0022H151.219V11.0854Z" />
      <path className="window" d="M33.3092 19.6261H24.4363C23.9618 19.6261 23.5822 19.2465 23.5822 18.7721V13.4578C23.5822 12.9833 23.9618 12.6038 24.4363 12.6038H33.3092C33.7836 12.6038 34.1633 12.9833 34.1633 13.4578V18.7246C34.2107 19.2465 33.7836 19.6261 33.3092 19.6261Z" />
      <path className="train-steel-3" d="M34.1632 14.5491H23.5822V15.2134H34.1632V14.5491Z" />
      <path className="window" d="M49.5365 19.6261H40.6637C40.1892 19.6261 39.8096 19.2465 39.8096 18.7721V13.4578C39.8096 12.9833 40.1892 12.6038 40.6637 12.6038H49.5365C50.011 12.6038 50.3906 12.9833 50.3906 13.4578V18.7246C50.3906 19.2465 50.011 19.6261 49.5365 19.6261Z" />
      <path className="train-steel-3" d="M50.3906 14.5491H39.8096V15.2134H50.3906V14.5491Z" />
      <path className="window" d="M65.7639 19.6261H56.8911C56.4166 19.6261 56.037 19.2465 56.037 18.7721V13.4578C56.037 12.9833 56.4166 12.6038 56.8911 12.6038H65.7639C66.2384 12.6038 66.618 12.9833 66.618 13.4578V18.7246C66.618 19.2465 66.2384 19.6261 65.7639 19.6261Z" />
      <path className="train-steel-3" d="M66.618 14.5491H56.037V15.2134H66.618V14.5491Z" />
      <path className="window" d="M81.9915 19.6261H73.1185C72.644 19.6261 72.2645 19.2465 72.2645 18.7721V13.4578C72.2645 12.9833 72.644 12.6038 73.1185 12.6038H81.9915C82.466 12.6038 82.8455 12.9833 82.8455 13.4578V18.7246C82.8455 19.2465 82.466 19.6261 81.9915 19.6261Z" />
      <path className="train-steel-3" d="M82.8455 14.5491H72.2645V15.2134H82.8455V14.5491Z" />
      <path className="window" d="M98.2189 19.6261H89.346C88.8716 19.6261 88.4919 19.2465 88.4919 18.7721V13.4578C88.4919 12.9833 88.8716 12.6038 89.346 12.6038H98.2189C98.6934 12.6038 99.073 12.9833 99.073 13.4578V18.7246C99.073 19.2465 98.6934 19.6261 98.2189 19.6261Z" />
      <path className="train-steel-3" d="M99.073 14.5491H88.4919V15.2134H99.073V14.5491Z" />
      <path className="window" d="M114.446 19.6261H105.573C105.099 19.6261 104.719 19.2465 104.719 18.7721V13.4578C104.719 12.9833 105.099 12.6038 105.573 12.6038H114.446C114.921 12.6038 115.3 12.9833 115.3 13.4578V18.7246C115.3 19.2465 114.921 19.6261 114.446 19.6261Z" />
      <path className="train-steel-3" d="M115.253 14.5491H104.672V15.2134H115.253V14.5491Z" />
      <path className="window" d="M130.626 19.6261H121.753C121.279 19.6261 120.899 19.2465 120.899 18.7721V13.4578C120.899 12.9833 121.279 12.6038 121.753 12.6038H130.626C131.101 12.6038 131.48 12.9833 131.48 13.4578V18.7246C131.528 19.2465 131.148 19.6261 130.626 19.6261Z" />
      <path className="train-steel-3" d="M131.48 14.5491H120.899V15.2134H131.48V14.5491Z" />
      <path className="window" d="M146.854 19.6261H137.981C137.506 19.6261 137.127 19.2465 137.127 18.7721V13.4578C137.127 12.9833 137.506 12.6038 137.981 12.6038H146.854C147.328 12.6038 147.708 12.9833 147.708 13.4578V18.7246C147.755 19.2465 147.328 19.6261 146.854 19.6261Z" />
      <path className="train-steel-3" d="M147.708 14.5491H137.127V15.2134H147.708V14.5491Z" />
      <path className="train-steel-4" d="M5.64648 29.0684H0.806763V8.66549C0.806763 7.33693 1.89812 6.24561 3.22668 6.24561C4.55524 6.24561 5.64648 7.33693 5.64648 8.66549V29.0684Z" />
      <path className="train-color" d="M20.7827 9.51953H8.58826V29.021H20.7827V9.51953Z" />
      <path className="train-color-shade" d="M21.0199 29.3057H8.35107V9.32983H21.0199V29.3057ZM8.82556 28.8313H20.5454V9.80432H8.82556V28.8313Z" />
      <path className="window" d="M18.6949 11.8447H14.6617V20.3855H18.6949V11.8447Z" />
      <path className="train-color-shade" d="M12.2893 9.51978H11.8148V29.0212H12.2893V9.51978Z" />
      <path className="train-steel-3" d="M137.839 1.07382C137.839 2.54472 136.652 3.73094 135.181 3.73094C133.71 3.73094 132.524 2.54472 132.524 1.07382C132.524 1.07382 132.667 0.0773926 135.181 0.0773926C137.696 0.0773926 137.839 1.07382 137.839 1.07382Z" />
      <path className="train-steel-3" d="M145.668 1.07382C145.668 2.54472 144.481 3.73094 143.01 3.73094C141.54 3.73094 140.353 2.54472 140.353 1.07382C140.353 1.07382 140.496 0.0773926 143.01 0.0773926C145.525 0.0773926 145.668 1.07382 145.668 1.07382Z" />
    </svg>
    <img className="flixtrain-lettering" src={flixtrain} alt="" aria-hidden="true" />
    <img className="flixtrain-lettering" src={flixtrain} alt="" aria-hidden="true" />
  </div>
);

const Railroad = () => (
  <div className="landscape__railroad">
    <div className="landscape__train">
      <Locomotive />
      <Wagon />
      <Wagon />
    </div>
    <div className="railroad-track" />
    <svg className="railroad-base" width="277" height="68" viewBox="0 0 277 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M255.968 65.2533V37.4484C255.968 26.298 265.03 17.2353 276.181 17.2353V0.723145H0.409031V17.2353C11.5595 17.2353 20.6222 26.298 20.6222 37.4484V65.2533C20.6222 66.5819 19.5309 67.6732 18.2023 67.6732H51.5113C50.1827 67.6732 49.0914 66.5819 49.0914 65.2533V37.4484C49.0914 26.298 58.1541 17.2353 69.3045 17.2353C80.455 17.2353 89.5176 26.298 89.5176 37.4484V65.2533C89.5176 66.5819 88.4263 67.6732 87.0978 67.6732H120.407C119.078 67.6732 117.987 66.5819 117.987 65.2533V37.4484C117.987 26.298 127.05 17.2353 138.2 17.2353C149.35 17.2353 158.413 26.298 158.413 37.4484V65.2533C158.413 66.5819 157.322 67.6732 155.993 67.6732H189.302C187.974 67.6732 186.882 66.5819 186.882 65.2533V37.4484C186.882 26.298 195.945 17.2353 207.096 17.2353C218.246 17.2353 227.309 26.298 227.309 37.4484V65.2533C227.309 66.5819 226.217 67.6732 224.889 67.6732H258.198C257.012 67.6257 255.968 66.5819 255.968 65.2533Z" />
    </svg>
    <svg className="railroad-base" width="277" height="68" viewBox="0 0 277 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M255.968 65.2533V37.4484C255.968 26.298 265.03 17.2353 276.181 17.2353V0.723145H0.409031V17.2353C11.5595 17.2353 20.6222 26.298 20.6222 37.4484V65.2533C20.6222 66.5819 19.5309 67.6732 18.2023 67.6732H51.5113C50.1827 67.6732 49.0914 66.5819 49.0914 65.2533V37.4484C49.0914 26.298 58.1541 17.2353 69.3045 17.2353C80.455 17.2353 89.5176 26.298 89.5176 37.4484V65.2533C89.5176 66.5819 88.4263 67.6732 87.0978 67.6732H120.407C119.078 67.6732 117.987 66.5819 117.987 65.2533V37.4484C117.987 26.298 127.05 17.2353 138.2 17.2353C149.35 17.2353 158.413 26.298 158.413 37.4484V65.2533C158.413 66.5819 157.322 67.6732 155.993 67.6732H189.302C187.974 67.6732 186.882 66.5819 186.882 65.2533V37.4484C186.882 26.298 195.945 17.2353 207.096 17.2353C218.246 17.2353 227.309 26.298 227.309 37.4484V65.2533C227.309 66.5819 226.217 67.6732 224.889 67.6732H258.198C257.012 67.6257 255.968 66.5819 255.968 65.2533Z" />
    </svg>
    <svg className="railroad-base" width="277" height="68" viewBox="0 0 277 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M255.968 65.2533V37.4484C255.968 26.298 265.03 17.2353 276.181 17.2353V0.723145H0.409031V17.2353C11.5595 17.2353 20.6222 26.298 20.6222 37.4484V65.2533C20.6222 66.5819 19.5309 67.6732 18.2023 67.6732H51.5113C50.1827 67.6732 49.0914 66.5819 49.0914 65.2533V37.4484C49.0914 26.298 58.1541 17.2353 69.3045 17.2353C80.455 17.2353 89.5176 26.298 89.5176 37.4484V65.2533C89.5176 66.5819 88.4263 67.6732 87.0978 67.6732H120.407C119.078 67.6732 117.987 66.5819 117.987 65.2533V37.4484C117.987 26.298 127.05 17.2353 138.2 17.2353C149.35 17.2353 158.413 26.298 158.413 37.4484V65.2533C158.413 66.5819 157.322 67.6732 155.993 67.6732H189.302C187.974 67.6732 186.882 66.5819 186.882 65.2533V37.4484C186.882 26.298 195.945 17.2353 207.096 17.2353C218.246 17.2353 227.309 26.298 227.309 37.4484V65.2533C227.309 66.5819 226.217 67.6732 224.889 67.6732H258.198C257.012 67.6257 255.968 66.5819 255.968 65.2533Z" />
    </svg>
    <svg className="railroad-base" width="277" height="68" viewBox="0 0 277 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M255.968 65.2533V37.4484C255.968 26.298 265.03 17.2353 276.181 17.2353V0.723145H0.409031V17.2353C11.5595 17.2353 20.6222 26.298 20.6222 37.4484V65.2533C20.6222 66.5819 19.5309 67.6732 18.2023 67.6732H51.5113C50.1827 67.6732 49.0914 66.5819 49.0914 65.2533V37.4484C49.0914 26.298 58.1541 17.2353 69.3045 17.2353C80.455 17.2353 89.5176 26.298 89.5176 37.4484V65.2533C89.5176 66.5819 88.4263 67.6732 87.0978 67.6732H120.407C119.078 67.6732 117.987 66.5819 117.987 65.2533V37.4484C117.987 26.298 127.05 17.2353 138.2 17.2353C149.35 17.2353 158.413 26.298 158.413 37.4484V65.2533C158.413 66.5819 157.322 67.6732 155.993 67.6732H189.302C187.974 67.6732 186.882 66.5819 186.882 65.2533V37.4484C186.882 26.298 195.945 17.2353 207.096 17.2353C218.246 17.2353 227.309 26.298 227.309 37.4484V65.2533C227.309 66.5819 226.217 67.6732 224.889 67.6732H258.198C257.012 67.6257 255.968 66.5819 255.968 65.2533Z" />
    </svg>
    <svg className="railroad-base" width="277" height="68" viewBox="0 0 277 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M255.968 65.2533V37.4484C255.968 26.298 265.03 17.2353 276.181 17.2353V0.723145H0.409031V17.2353C11.5595 17.2353 20.6222 26.298 20.6222 37.4484V65.2533C20.6222 66.5819 19.5309 67.6732 18.2023 67.6732H51.5113C50.1827 67.6732 49.0914 66.5819 49.0914 65.2533V37.4484C49.0914 26.298 58.1541 17.2353 69.3045 17.2353C80.455 17.2353 89.5176 26.298 89.5176 37.4484V65.2533C89.5176 66.5819 88.4263 67.6732 87.0978 67.6732H120.407C119.078 67.6732 117.987 66.5819 117.987 65.2533V37.4484C117.987 26.298 127.05 17.2353 138.2 17.2353C149.35 17.2353 158.413 26.298 158.413 37.4484V65.2533C158.413 66.5819 157.322 67.6732 155.993 67.6732H189.302C187.974 67.6732 186.882 66.5819 186.882 65.2533V37.4484C186.882 26.298 195.945 17.2353 207.096 17.2353C218.246 17.2353 227.309 26.298 227.309 37.4484V65.2533C227.309 66.5819 226.217 67.6732 224.889 67.6732H258.198C257.012 67.6257 255.968 66.5819 255.968 65.2533Z" />
    </svg>
  </div>
);

export default Railroad;
