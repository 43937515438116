import { Grid, GridCol, Button, Input, ButtonGroup } from "@flixbus/honeycomb-react";
import { Icon, IconDownload } from "@flixbus/honeycomb-icons-react";

const DownloadExcel = ({ fileName, searchResult, setFileName, getExcelId }) => {
    return (
        <>
            {
                searchResult?.success && searchResult?.data?.passengercount > 0 &&
                <Grid align="center" extraClasses="flix-space-4-top flix-space-8-bottom">
                    <GridCol size={12} md={10}>
                        <Input
                            id="label"
                            label="Your document is ready"
                            value={fileName}
                            onChange={(e) => setFileName(e.target.value)}
                            info="to change the file name type it in the input field"
                        />
                    </GridCol>
                    <GridCol size={12} md={2}>
                        <ButtonGroup>
                            <Button appearance="secondary" display="block" onClick={() => getExcelId()} aria-label="Download">
                                <Icon aria-hidden="true" InlineIcon={IconDownload} /> Download
                            </Button>
                        </ButtonGroup>
                    </GridCol>
                </Grid>
            }
        </>
    );
}

export default DownloadExcel;