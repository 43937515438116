import {
    FormRow,
    Grid,
    GridCol,
    Infobox,
    Heading,
    List,
    Box,
    Text
} from "@flixbus/honeycomb-react";
const Summary = ({ result }) => {
    return (
        <>
            <Grid align="bottom" extraClasses='flix-space-2-top'>
                {result?.data?.passengercount > 0 ?
                    <Heading size={2}>
                        Data found
                    </Heading>
                    :
                    <Heading size={2}>
                        No data found
                    </Heading>
                }
            </Grid>
            {result?.data?.passengercount > 0 &&
                <Grid align="bottom" extraClasses='flix-space-2-top'>
                    <GridCol size={1} md={3}>
                        <Box extraClasses="shadow border-0">
                            <Heading size={3} sectionHeader>{result?.data?.linecount}</Heading>
                            <Text>Lines</Text>
                        </Box>
                    </GridCol>
                    <GridCol size={1} md={3}>
                        <Box extraClasses="shadow border-0">
                            <Heading size={3} sectionHeader>{result?.data?.tripcount}</Heading>
                            <Text>Trips</Text>
                        </Box>
                    </GridCol>
                    <GridCol size={1} md={3}>
                        <Box extraClasses="shadow border-0">
                            <Heading size={3} sectionHeader>{result?.data?.ridecount}</Heading>
                            <Text>Rides</Text>
                        </Box>
                    </GridCol>
                    <GridCol size={1} md={3}>
                        <Box extraClasses="shadow border-0">
                            <Heading size={3} sectionHeader>{result?.data?.passengercount}</Heading>
                            <Text>Passenger</Text>
                        </Box>
                    </GridCol>
                </Grid>
            }
        </>
    );
}

export default Summary;