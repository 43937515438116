import { AutocompleteInput, AutocompleteOptions } from "@flixbus/honeycomb-react";
import { Icon } from "@flixbus/honeycomb-icons-react";
import { Autocomplete } from "@flixbus/honeycomb-react";
import { useState } from "react";

const CustomAutoComplete = ({ placeHolder, label, data, value, setValue, iconLeft, iconRight, filterStart = 0, disabled = false }) => {
    const [optionsData, setOptionsData] = useState([...data]);

    const filterData = (searchQuery, _data) => (
        new Promise((resolve) => {
            setTimeout(() => {
                let res = "";
                if (searchQuery?.length >= filterStart)
                    res = _data?.filter(item => (
                        item?.title?.toLowerCase().includes(searchQuery?.toLowerCase())
                    ));
                resolve(res);
            }, 200);
        })
    );


    return (
        <Autocomplete
            onChange={(e) => {
            }}
            onDebounce={(e) => {
                filterData(e?.target?.value, data).then(
                    options => {
                        setOptionsData(options)
                    }
                )
            }}
            onSelect={(item) => {
                setOptionsData([]);
                setValue(item);
            }}
            options={optionsData}
            value={value?.title}
        >
            <AutocompleteInput
                id="autocompleteFrom"
                placeholder={placeHolder}
                disabled={disabled}
                iconLeft={iconLeft ? <Icon aria-hidden="true" InlineIcon={iconLeft} /> : null}
                iconRight={iconRight ? <Icon aria-hidden="true" InlineIcon={iconRight} /> : null}
                label={label}
                type="search"
                onFocus={() => filterStart === 0 && setOptionsData([...data])}
            />
            <AutocompleteOptions
                optionsToDisplay={10}
                optionHasSubtitle={false}
            />
        </Autocomplete>
    );
}

export default CustomAutoComplete;