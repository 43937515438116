import React, { useContext, useState, useEffect } from 'react';
import StateContext from '../../contexts/StateContext';

const Loader = () => {
  const appState = useContext(StateContext);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    let timeout;
    if (appState?.isLoading) {
      timeout = setTimeout(() => {
        setShowMessage(true);
      }, 15000);
    }
    else{
      setShowMessage(false);
    }

    return () => clearTimeout(timeout);
  }, [appState?.isLoading]);

  return (
    <>
      {appState?.isLoading &&
        <div className="loader-container">
          <div aria-busy="true" aria-live="polite">
            <div className="flix-spinner flix-spinner--lg"></div>
          </div>
          {showMessage && <p>This process takes a while. Please wait...</p>}
        </div>
      }
    </>
  );
};

export default Loader;
