import React from 'react';
import './bus.scss';

const Bus = () => (
  <svg className="landscape__bus" width="126" height="45" viewBox="0 0 126 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="window-base-blue" d="M71.9819 1.85181H23.4892C22.8249 1.85181 22.1606 2.04161 21.5913 2.42119L20.1204 3.37016H75.3507L73.8798 2.42119C73.3104 2.04161 72.6461 1.85181 71.9819 1.85181Z" />
    <path className="dark-blue-color" d="M87.2129 33.9177L90.3445 33.6805L89.87 32.1621H87.2129V33.9177Z" />
    <path className="dark-blue-color" d="M94.1405 28.9355C94.1405 31.5452 92.3375 33.6804 90.1074 33.6804C87.8773 33.6804 86.0742 31.5452 86.0742 28.9355C86.0742 26.3259 87.8773 24.1907 90.1074 24.1907C92.3375 24.1907 94.1405 26.3259 94.1405 28.9355Z" />
    <path className="blue-highlight" d="M91.246 29.1729C91.246 31.7825 89.4429 33.9177 87.2128 33.9177C84.9827 33.9177 83.1797 31.7825 83.1797 29.1729C83.1797 26.5632 84.9827 24.428 87.2128 24.428C89.4429 24.4754 91.246 26.5632 91.246 29.1729Z" />
    <path className="dark-blue-color" d="M91.246 31.1181H83.5119L83.2747 30.3115H91.246V31.1181Z" />
    <path className="dark-blue-color" d="M32.2673 33.9177L35.5413 33.633L34.9245 32.1621H32.2673V33.9177Z" />
    <path className="dark-blue-color" d="M39.1949 28.9355C39.1949 31.5452 37.3919 33.6804 35.1618 33.6804C32.9317 33.6804 31.1287 31.5452 31.1287 28.9355C31.1287 26.3259 32.9317 24.1907 35.1618 24.1907C37.3919 24.2381 39.1949 26.3259 39.1949 28.9355Z" />
    <path className="blue-highlight" d="M36.3004 29.1729C36.3004 31.7825 34.4974 33.9177 32.2673 33.9177C30.0372 33.9177 28.2341 31.7825 28.2341 29.1729C28.2341 26.5632 30.0372 24.428 32.2673 24.428C34.4974 24.4754 36.3004 26.5632 36.3004 29.1729Z" />
    <path className="dark-blue-color" d="M36.3005 30.5489H28.5663L28.3291 29.7422H36.3005V30.5489Z" />
    <path className="dark-blue-color" d="M26.0041 24.6177H8.63782L14.3791 31.4028H28.2342L26.0041 24.6177Z" />
    <path className="dark-green-color" d="M19.7883 31.4028V26.136H73.6901C71.7921 26.136 70.2737 27.6543 70.2737 29.5048V30.7859C70.2737 31.1181 69.9891 31.4028 69.6569 31.4028" />
    <path className="window-base-blue" d="M20.1204 35.0089L23.2046 34.7717L22.825 33.3008H20.1204V35.0089Z" />
    <path className="window-base-blue" d="M26.8107 30.0742C26.8107 32.6839 25.0076 34.8191 22.7775 34.8191C20.5474 34.8191 18.7444 32.6839 18.7444 30.0742C18.7444 27.4645 20.5474 25.3293 22.7775 25.3293C25.0076 25.3293 26.8107 27.4645 26.8107 30.0742Z" />
    <path className="tires-1" d="M24.1536 30.3115C24.1536 32.9212 22.3505 35.0564 20.1204 35.0564C17.8903 35.0564 16.0873 32.9212 16.0873 30.3115C16.0873 27.7018 17.8903 25.5667 20.1204 25.5667C22.3505 25.5667 24.1536 27.7018 24.1536 30.3115Z" />
    <path className="dark-blue-color" d="M23.2995 30.3116C23.2995 32.3519 21.8761 34.0126 20.1205 34.0126C18.3649 34.0126 16.9415 32.3519 16.9415 30.3116C16.9415 28.2713 18.3649 26.6106 20.1205 26.6106C21.8761 26.6106 23.2995 28.2713 23.2995 30.3116Z" />
    <path className="tires-2" d="M22.5877 30.3115C22.5877 31.8773 21.4964 33.1584 20.1204 33.1584C18.7444 33.1584 17.6531 31.8773 17.6531 30.3115C17.6531 28.7457 18.7444 27.4646 20.1204 27.4646C21.4964 27.4646 22.5877 28.7457 22.5877 30.3115Z" />
    <path className="dark-blue-color" opacity="0.5" d="M26.1938 27.9391C26.1938 27.9391 18.3174 27.3223 16.9414 28.2238C16.3245 28.6509 16.0873 29.4575 16.0873 29.4575C16.0873 29.4575 16.1347 27.4172 16.3245 27.0851C16.5143 26.8004 17.0363 25.9463 17.0363 25.9463H26.1938V27.9391Z" />
    <path className="wheels-highlight" d="M21.5914 29.0305C21.5914 29.4101 21.3067 29.6948 20.9271 29.6948C20.5475 29.6948 20.2628 29.4101 20.2628 29.0305C20.2628 28.6509 20.5475 28.3662 20.9271 28.3662C21.3067 28.4137 21.5914 28.6983 21.5914 29.0305Z" />
    <path className="dark-blue-color" d="M66.5728 31.4028H83.6542V24.6177H66.5728V31.4028Z" />
    <path className="medium-green-color" d="M14.1893 31.4028C14.5215 31.4028 14.8062 31.1181 14.8062 30.7859V29.5048C14.8062 27.6543 16.3245 26.136 18.2224 26.136H19.7883H14.2842L13.7148 31.0232L14.1893 31.4028Z" />
    <path className="window-base-blue" d="M75.3508 35.0089L78.3875 34.7717L78.0553 33.3008H75.3508V35.0089Z" />
    <path className="window-base-blue" d="M82.0411 30.0742C82.0411 32.6839 80.2381 34.8191 78.008 34.8191C75.7779 34.8191 73.9749 32.6839 73.9749 30.0742C73.9749 27.4645 75.7779 25.3293 78.008 25.3293C80.2381 25.3293 82.0411 27.4645 82.0411 30.0742Z" />
    <path className="tires-1" d="M79.3839 30.3115C79.3839 32.9212 77.5809 35.0564 75.3508 35.0564C73.1207 35.0564 71.3176 32.9212 71.3176 30.3115C71.3176 27.7018 73.1207 25.5667 75.3508 25.5667C77.5809 25.5667 79.3839 27.7018 79.3839 30.3115Z" />
    <path className="dark-blue-color" d="M78.5298 30.3116C78.5298 32.3519 77.1063 34.0126 75.3507 34.0126C73.5951 34.0126 72.1716 32.3519 72.1716 30.3116C72.1716 28.2713 73.5951 26.6106 75.3507 26.6106C77.1063 26.6106 78.5298 28.2713 78.5298 30.3116Z" />
    <path className="tires-2" d="M77.8181 30.3115C77.8181 31.8773 76.7267 33.1584 75.3507 33.1584C73.9747 33.1584 72.8833 31.8773 72.8833 30.3115C72.8833 28.7457 73.9747 27.4646 75.3507 27.4646C76.7267 27.4646 77.8181 28.7457 77.8181 30.3115Z" />
    <path className="dark-blue-color" opacity="0.5" d="M81.4241 27.9391C81.4241 27.9391 73.5477 27.3223 72.1717 28.2238C71.5548 28.6509 71.3176 29.4575 71.3176 29.4575C71.3176 29.4575 71.3651 27.4172 71.5549 27.0851C71.7447 26.8004 72.2666 25.9463 72.2666 25.9463H81.4241V27.9391Z" />
    <path className="wheels-highlight" d="M76.8215 29.0305C76.8215 29.4101 76.5368 29.6948 76.1572 29.6948C75.7776 29.6948 75.4929 29.4101 75.4929 29.0305C75.4929 28.6509 75.7776 28.3662 76.1572 28.3662C76.4894 28.4137 76.8215 28.6983 76.8215 29.0305Z" />
    <path className="light-green-color" d="M14.1895 30.786V29.5049C14.1895 27.6544 15.7078 26.1361 17.6057 26.1361H22.92C24.5333 26.1361 25.8618 27.4646 25.8618 29.0779V30.5963C25.8618 30.9759 26.194 31.308 26.5736 31.308L69.2774 31.3554C69.5147 31.2605 69.657 31.0233 69.657 30.786V29.5049C69.657 27.6544 71.1754 26.1361 73.0733 26.1361H78.3876C80.0009 26.1361 81.3294 27.4646 81.3294 29.0779V30.5963C81.3294 30.9759 81.6615 31.308 82.0411 31.308L91.4834 31.4503C93.0492 31.4503 92.4798 29.5998 92.5273 28.8881C92.812 25.709 93.0018 17.6902 88.6365 4.87909C88.2095 3.59797 87.0232 2.7439 85.6472 2.7439H8.25837C7.0247 2.7439 1.90024 5.16378 1.85279 6.39745L1.1885 28.6509C1.14105 29.3626 1.33086 30.2167 2.0426 30.3116C2.18494 30.4065 14.1895 30.786 14.1895 30.786Z" />
    <path className="dark-blue-color" d="M89.8226 17.9274L85.5997 10.5728C85.5048 10.383 85.2675 10.2407 85.0303 10.2407L2.46969 8.53253C2.0901 8.53253 1.90027 8.62743 1.90027 8.29529L3.18142 4.40449C3.18142 4.40449 4.65228 2.79123 8.35327 2.79123H85.6945C85.6945 2.79123 87.64 2.60144 88.9211 5.59071C89.7751 7.58356 90.6292 13.7519 91.1037 17.5478C91.1511 18.2595 90.2022 18.5442 89.8226 17.9274Z" />
    <path className="dark-blue-color" d="M98.7429 9.10205H103.013L104.342 11.427C104.532 11.7117 104.864 11.9015 105.196 11.9015H105.433L105.196 11.7592L103.298 8.48522L97.7939 8.15308C97.6516 8.15308 97.6041 8.34289 97.699 8.43779L97.9363 8.58013C98.221 8.72247 98.5056 9.10205 98.7429 9.10205Z" />
    <path className="light-green-color" d="M103.156 8.48511H103.535C103.535 8.48511 105.101 8.81724 105.196 9.10193L106.05 11.3795C106.145 11.6167 106.145 11.854 105.86 11.854H105.243C105.101 11.854 105.006 11.7591 104.911 11.6642L103.156 8.48511Z" />
    <path className="medium-green-color" d="M103.488 8.48511L104.816 8.62745C105.149 8.62745 105.433 8.81724 105.528 9.10193L106.382 11.3795C106.477 11.6167 106.287 11.854 106.05 11.854H105.576C105.433 11.854 105.338 11.7591 105.243 11.6642L103.488 8.48511Z" />
    <path className="medium-green-color" d="M80.0481 14.8434L80.7599 19.3035L81.4241 23.7637V27.8917V29.932V30.4539C81.4241 30.6437 81.4241 30.7861 81.519 30.881C81.6614 31.1657 81.9935 31.2606 82.3256 31.308C81.9935 31.3555 81.5664 31.2605 81.3767 30.9284C81.2818 30.7861 81.2343 30.5488 81.2343 30.4065V29.8846V27.8443V23.7163L80.5701 19.2561L79.9058 14.7959L80.0481 14.8434Z" />
    <path className="dark-blue-color" d="M85.1726 2.79134C85.1726 2.79134 96.0384 2.55411 98.7904 4.64185C102.586 7.53622 104.152 17.3581 104.532 22.9096C104.769 27.1325 104.342 30.0269 102.729 30.0269L89.0159 31.4503L85.1726 2.79134Z" />
    <path className="medium-green-color" d="M95.896 26.326L94.8995 25.1398L91.3883 23.7637L91.0088 31.403C91.0088 31.403 95.896 31.1657 99.6444 30.7861C102.396 30.5014 103.962 30.3117 104.294 28.8407C104.532 27.7969 104.674 25.4245 104.484 23.7163L102.349 25.9938L101.353 26.2311H95.896V26.326Z" />
    <path className="light-green-color" d="M91.6255 31.4503L81.9936 31.4028L79.8584 2.79126H84.7455C84.7455 2.79126 86.0266 2.98105 87.3077 4.45196C88.5888 5.92287 89.775 9.48154 90.8189 16.3616C91.1985 19.0187 91.4357 21.8656 91.5306 24.333C91.7204 28.3186 91.6255 31.4503 91.6255 31.4503Z" />
    <path className="dark-blue-color" d="M91.5782 23.8585L80.9496 19.0661C80.6175 18.9238 80.3803 18.5917 80.3328 18.2595L79.8109 14.606V2.79126H84.793C84.793 2.79126 86.0741 2.98105 87.3552 4.45196C88.6363 5.92287 89.8226 9.48153 90.8665 16.3616C91.2935 18.9713 91.3409 21.3437 91.5782 23.8585Z" />
    <path className="window-base-blue" d="M1.90022 8.2955C1.90022 5.73327 3.98799 3.64551 6.55022 3.64551H80.3804V14.0842H19.9307H2.99156C2.37473 14.0842 1.85278 13.5623 1.85278 12.9455V8.2955H1.90022Z" />
    <path className="dark-blue-color" d="M80.9496 14.6061H2.99139C2.04242 14.6061 1.33069 13.8469 1.33069 12.9454V8.29545C1.33069 5.44853 3.70312 3.12354 6.55005 3.12354H80.9021L80.9496 14.6061ZM2.42197 8.29545V12.9454C2.42197 13.2775 2.65925 13.5148 2.99139 13.5148H79.8109V4.21485H6.55005C4.31996 4.21485 2.46942 6.01791 2.42197 8.29545Z" />
    <path className="medium-green-color" d="M81.3292 23.7163H1.37817V23.9061H81.3292V23.7163Z" />
    <path className="blue-highlight" d="M67.854 6.72968H26.5261C26.1465 6.72968 25.8618 6.445 25.8618 6.06541C25.8618 5.68583 26.1465 5.40112 26.5261 5.40112H67.854C68.2335 5.40112 68.5182 5.68583 68.5182 6.06541C68.5182 6.445 68.2335 6.72968 67.854 6.72968Z" />
    <path className="dark-blue-color" d="M62.9191 4.03442H61.7804V14.6155H62.9191V4.03442Z" />
    <path className="dark-blue-color" d="M48.8268 4.03442H47.688V14.6155H48.8268V4.03442Z" />
    <path className="dark-blue-color" d="M34.7347 4.03442H33.5959V14.6155H34.7347V4.03442Z" />
    <path className="dark-blue-color" d="M20.6423 4.03442H19.5037V14.6155H20.6423V4.03442Z" />
    <path className="medium-green-color" d="M58.2692 23.811H58.0793V30.786H58.2692V23.811Z" />
    <path className="medium-green-color" d="M50.8198 23.811H50.6301V30.786H50.8198V23.811Z" />
    <path className="medium-green-color" d="M32.3622 23.811H32.1725V30.786H32.3622V23.811Z" />
    <path className="window-base-blue" d="M80.1906 4.35711C80.238 4.26221 80.2855 4.26223 80.3329 4.16733C80.5227 4.16733 80.7125 4.16733 80.9023 4.16733C82.8002 4.16733 85.315 4.11987 86.5486 4.59436C88.3517 5.25864 89.9175 15.4127 90.2496 21.2488C90.2496 21.5335 89.9649 21.7233 89.7277 21.6284L81.8987 18.0698C81.6614 17.9749 81.4717 17.7376 81.4717 17.4529L80.1906 4.35711Z" />
    <path className="medium-green-color" d="M69.657 30.5962H25.8618V30.8809C25.8618 31.1655 26.099 31.4028 26.3837 31.4028H69.1825C69.4197 31.4028 69.657 31.213 69.657 30.9283V30.5962Z" />
    <path className="medium-green-color" d="M14.3793 30.5962L1.28345 29.5049C1.28345 29.5049 1.37833 30.359 2.04261 30.359L14.1895 31.4028L14.3793 30.5962Z" />
    <path className="window-base-blue" d="M88.5414 4.78417C88.3991 4.54693 88.5415 4.21478 88.8262 4.21478C91.1986 4.16733 96.6077 4.02499 98.6955 5.401C100.499 6.58722 101.542 9.48159 102.586 13.23C103.44 16.2667 103.82 20.9642 103.915 22.3402C103.915 22.5774 103.773 22.7198 103.535 22.7198L92.3848 22.8621C92.195 22.8621 92.0527 22.7198 92.0052 22.53C91.8629 20.9167 91.1037 12.2811 89.1109 6.34997C89.016 5.92294 88.7312 5.11631 88.5414 4.78417Z" />
    <path className="light-green-color" d="M74.7339 22.4351C74.639 22.4351 74.5916 22.3877 74.5916 22.2928C74.5916 22.1979 74.639 22.1504 74.7339 22.1504H75.683C75.7779 22.1504 75.8254 22.1979 75.8254 22.2928C75.8254 22.3877 75.7779 22.4351 75.683 22.4351H74.7339Z" />
    <path className="blue-highlight" d="M86.8808 8.01056H91.5308L93.1441 10.8101C93.3813 11.1896 93.7609 11.4269 94.1879 11.4269H94.4726L94.1879 11.2845L91.9104 7.29883H85.7895C85.5997 7.29883 85.5522 7.53608 85.6946 7.63098L85.9793 7.77332C86.264 7.96311 86.5486 8.01056 86.8808 8.01056Z" />
    <path className="light-green-color" d="M91.6731 7.29883H92.1001C92.1001 7.29883 94.8996 7.77332 95.0419 8.10546L96.0857 10.8575C96.1806 11.1422 95.9908 11.4743 95.6587 11.4743H94.1878C94.0455 11.4743 93.8557 11.3794 93.8083 11.2371L91.6731 7.29883Z" />
    <path className="medium-green-color" d="M92.1475 7.29883L94.6623 7.53607C95.0419 7.53607 95.3741 7.77332 95.5164 8.10546L96.5603 10.8101C96.6552 11.0947 96.4654 11.4269 96.1332 11.4269H94.6623C94.5199 11.4269 94.3302 11.332 94.2827 11.1896L92.1475 7.29883Z" />
    <path className="light-green-color" d="M91.7679 31.4504L91.5781 31.4029V30.1218C91.5781 30.1218 91.5781 31.4029 92.7168 31.308L91.7679 31.4504Z" />
    <path className="bumper-2" d="M102.254 27.7494L103.345 25.4244L104.579 23.8111L104.01 24.0484C103.583 24.2381 103.203 24.5228 102.918 24.855L102.349 25.6142C102.254 25.7565 102.159 25.8514 102.017 25.9463C101.922 25.9938 101.78 26.0412 101.685 26.0412L96.8924 26.2785C96.6552 26.2785 96.4653 26.1836 96.2755 26.0412L95.0893 24.9499C94.8046 24.6652 94.425 24.4754 94.0454 24.3805L91.6255 23.7637L94.6148 25.7091L95.9434 28.2239L102.254 27.7494Z" />
    <path className="tires-1" d="M100.831 27.2273L98.2685 27.4171C98.1736 27.4171 98.0312 27.3222 98.0312 27.2273C98.0312 27.1324 98.1262 26.9901 98.2211 26.9901L100.783 26.8003C100.878 26.8003 101.021 26.8952 101.021 26.9901C101.021 27.085 100.973 27.1799 100.831 27.2273Z" />
    <path className="bumper-1" d="M92.4796 24.2856L94.4725 25.6616L96.7026 27.9391C96.845 28.1289 96.7026 28.4136 96.4654 28.4136C95.5164 28.3662 93.6659 28.4136 93.3337 27.8917C92.9067 27.2274 92.3847 25.3769 92.1949 24.5228C92.1475 24.333 92.3373 24.1907 92.4796 24.2856Z" />
    <path className="bumper-1" d="M104.627 24.2856C104.721 25.3295 104.627 26.3734 104.342 27.0377C104.105 27.5596 102.918 27.8443 102.254 27.8917C102.112 27.8917 102.017 27.607 102.112 27.4172L103.393 25.1397C103.44 25.0922 103.488 25.0448 103.535 24.9499L104.579 23.7637C104.579 23.7637 104.627 23.9535 104.627 24.2856Z" />
    <path className="bumper-green" d="M101.78 29.5048L97.6042 29.9319C97.4144 29.9319 97.2721 29.837 97.2246 29.6472C97.2246 29.4574 97.3195 29.315 97.5093 29.2676L101.685 28.8406C101.875 28.8406 102.017 28.9355 102.064 29.1253C102.112 29.315 101.97 29.5048 101.78 29.5048Z" />
    <path className="bumper-2" d="M93.1913 25.4244C93.1439 25.3769 93.049 25.4244 93.049 25.5193C93.1439 25.9463 93.4286 26.9902 93.9031 27.4173C94.3776 27.8443 95.1842 27.8918 95.5639 27.8918C95.6588 27.8918 95.7062 27.7969 95.6113 27.7494L94.3302 26.231L93.1913 25.4244Z" />
    <path className="front-lights" d="M94.2353 27.2275C94.4251 27.4173 94.4726 27.6545 94.3302 27.7969C94.1879 27.9392 93.9506 27.8918 93.7608 27.702C93.571 27.5122 93.5236 27.2749 93.6659 27.1326C93.8083 26.9902 94.0455 27.0377 94.2353 27.2275Z" />
    <path className="bumper-2" d="M104.437 24.9498C104.484 24.9023 104.579 24.9498 104.532 25.0447C104.484 25.4717 104.437 26.1835 104.152 26.563C103.867 26.9426 103.346 27.0375 103.108 27.0375C103.061 27.0375 103.013 26.9426 103.061 26.8952L103.867 25.5192L104.437 24.9498Z" />
    <path className="front-lights" d="M103.203 26.136C103.108 26.2783 103.108 26.4681 103.156 26.563C103.251 26.6579 103.393 26.6104 103.488 26.4681C103.583 26.3258 103.583 26.136 103.535 26.0411C103.44 25.9462 103.298 25.9936 103.203 26.136Z" />
    <path className="blue-highlight" d="M99.6445 8.72246C99.4547 8.72246 99.2649 8.62757 99.2175 8.43778C99.2175 8.43778 98.8379 7.48881 98.221 6.87198C97.794 6.44494 96.5129 6.25514 95.8012 6.20769C95.564 6.20769 95.3267 5.97045 95.3741 5.7332C95.3741 5.49596 95.6114 5.25871 95.8486 5.30616C96.0859 5.30616 98.0787 5.40105 98.8853 6.25513C99.6445 7.0143 100.072 8.05818 100.072 8.10563C100.166 8.34288 100.072 8.62756 99.8343 8.72246C99.7869 8.67501 99.6919 8.72246 99.6445 8.72246Z" />
    <path className="blue-highlight" d="M100.356 10.7152C100.166 10.7152 99.9766 10.5728 99.9291 10.383C99.7868 9.90856 99.6919 9.76622 99.6919 9.76622C99.597 9.52898 99.6919 9.2443 99.9291 9.1494C100.166 9.05451 100.451 9.1494 100.546 9.38664C100.546 9.38664 100.641 9.62387 100.831 10.1458C100.925 10.383 100.783 10.6677 100.498 10.7152C100.451 10.7152 100.404 10.7152 100.356 10.7152Z" />
    <path className="blue-highlight" d="M83.8439 9.76624H81.2343C81.092 9.76624 80.9971 9.67134 80.9971 9.52899C80.9971 9.38665 81.092 9.29175 81.2343 9.29175H83.8439C83.9863 9.29175 84.0812 9.38665 84.0812 9.52899C84.0812 9.67134 83.9863 9.76624 83.8439 9.76624Z" />
    <path className="blue-highlight" d="M84.8879 9.76624H84.793C84.6507 9.76624 84.5558 9.67134 84.5558 9.52899C84.5558 9.38665 84.6507 9.29175 84.793 9.29175H84.8879C85.0302 9.29175 85.1251 9.38665 85.1251 9.52899C85.1251 9.67134 85.0302 9.76624 84.8879 9.76624Z" />
    <path className="medium-green-color" d="M7.87878 23.811H7.68896V30.1692H7.87878V23.811Z" />
    <path className="blue-highlight" d="M4.6048 10.9051C4.17777 10.9051 3.84558 10.5729 3.84558 10.1459V8.39028C3.84558 6.77702 5.17417 5.44849 6.78742 5.44849H8.40069C8.82772 5.44849 9.15985 5.78061 9.15985 6.20765C9.15985 6.63469 8.82772 6.96682 8.40069 6.96682H6.78742C5.9808 6.96682 5.36397 7.6311 5.36397 8.39028V10.1459C5.36397 10.5729 5.03184 10.9051 4.6048 10.9051Z" />
    <path className="blue-highlight" d="M12.1016 7.01422H10.6781C10.2511 7.01422 9.91895 6.68206 9.91895 6.25502C9.91895 5.82798 10.2511 5.49585 10.6781 5.49585H12.1016C12.5286 5.49585 12.8607 5.82798 12.8607 6.25502C12.8607 6.68206 12.5286 7.01422 12.1016 7.01422Z" />
    <path className="medium-green-color" d="M53.5244 26.4208C53.5244 26.1362 53.7617 25.8989 54.0463 25.8989H54.9953C55.28 25.8989 55.5173 26.1362 55.5173 26.4208C55.5173 26.7055 55.28 26.9428 54.9953 26.9428H54.0463C53.7142 26.9428 53.5244 26.7055 53.5244 26.4208Z" />
    <path className="light-green-color" d="M53.8564 26.3259C53.8564 26.1835 53.9513 26.0886 54.0937 26.0886H54.9004C55.0427 26.0886 55.1376 26.1835 55.1376 26.3259C55.1376 26.4682 55.0427 26.5631 54.9004 26.5631H54.0937C53.9513 26.5631 53.8564 26.4682 53.8564 26.3259Z" />
    <path className="flixbus-lettering" d="M32.552 19.1611H32.457V19.2086H32.552V19.1611Z" fill="white" />
    <path className="flixbus-lettering" d="M32.979 16.409C32.6943 16.409 32.457 16.6462 32.457 16.9309V21.9605H33.8805V19.7779H35.8733C36.158 19.7779 36.3953 19.5406 36.3953 19.2559V18.6391H33.8331V17.785C33.8331 17.6427 33.9754 17.5003 34.1177 17.5003H36.5376C36.8223 17.5003 37.0596 17.2631 37.0596 16.9784V16.3616H32.979V16.409Z" fill="white" />
    <path className="flixbus-lettering" d="M39.3845 20.8219C39.2422 20.8219 39.0999 20.6796 39.0999 20.5372V16.9311C39.0999 16.6464 38.8626 16.4092 38.5779 16.4092H37.6764V21.4387C37.6764 21.7234 37.9136 21.9607 38.1983 21.9607H41.0927C41.3774 21.9607 41.6147 21.7234 41.6147 21.4387V20.8219H39.3845Z" fill="white" />
    <path className="flixbus-lettering" d="M43.7023 21.9605H42.2788V19.0661C42.2788 18.7814 42.5161 18.5442 42.8008 18.5442H43.7023V21.9605Z" fill="white" />
    <path className="flixbus-lettering" d="M43.1804 18.0224H42.2788V16.9785C42.2788 16.6938 42.5161 16.4565 42.8008 16.4565H43.1329C43.4176 16.4565 43.6549 16.6938 43.6549 16.9785V17.5004C43.7023 17.7851 43.4651 18.0224 43.1804 18.0224Z" fill="white" />
    <path className="flixbus-lettering" d="M52.8599 21.0591H51.8635C51.5788 21.0591 51.3416 20.8219 51.3416 20.5372V19.5882H52.8125C53.1921 19.5882 53.5242 19.9204 53.5242 20.3C53.5717 20.727 53.2395 21.0591 52.8599 21.0591ZM51.389 17.3107H52.7175C53.0497 17.3107 53.3344 17.5954 53.3344 17.975C53.3344 18.3071 53.0497 18.5918 52.7175 18.6393H51.8161C51.5788 18.6393 51.3416 18.4495 51.3416 18.1648V17.3107H51.389ZM54.3782 19.2087C54.3308 19.1612 54.2834 19.1138 54.2359 19.1138C54.5206 18.8291 54.6155 18.4969 54.6155 18.0224C54.6155 17.026 53.9512 16.4092 52.9548 16.4092H50.7722C50.4875 16.4092 50.2502 16.6464 50.2502 16.9311V21.3913C50.2502 21.676 50.4875 21.9132 50.7722 21.9132H53.192C54.1885 21.9132 54.8053 21.3438 54.8053 20.3C54.8527 19.8729 54.6629 19.4934 54.3782 19.2087Z" fill="white" />
    <path className="flixbus-lettering" d="M59.9299 16.4092H59.3131C59.0284 16.4092 58.7912 16.6464 58.7912 16.9311V20.2051C58.7912 20.6796 58.459 21.0117 57.8897 21.0117H57.605C57.0356 21.0117 56.7035 20.6796 56.7035 20.2051V16.4092H56.0867C55.802 16.4092 55.5647 16.6464 55.5647 16.9311V20.3949C55.5647 21.3913 56.229 22.0556 57.4152 22.0556H58.0795C59.2657 22.0556 59.9299 21.3913 59.9299 20.3949V16.4092Z" fill="white" />
    <path className="flixbus-lettering" d="M62.2548 17.548H64.0578C64.3425 17.548 64.5798 17.3107 64.5798 17.026V16.4092H62.2548C61.3058 16.4092 60.5941 17.1683 60.5941 18.1173C60.5941 19.0188 61.3533 19.7306 62.2548 19.778H62.9666C63.2512 19.778 63.4885 20.0153 63.4885 20.3C63.4885 20.5847 63.2512 20.8219 62.9666 20.8219H60.6416V21.4387C60.6416 21.7234 60.8788 21.9607 61.1635 21.9607H62.9666C63.8681 21.9607 64.6273 21.2015 64.6273 20.2525C64.6273 19.351 63.9155 18.6392 62.9666 18.5918H62.2073C61.9226 18.5918 61.6854 18.3546 61.6854 18.0699C61.7329 17.7852 61.9701 17.548 62.2548 17.548Z" fill="white" />
    <path className="flixbus-lettering" d="M49.1115 20.9641L48.1626 19.6355L47.3086 20.7743L48.0203 21.7707C48.2575 22.0554 48.6371 22.1503 48.9692 21.913C49.3013 21.6758 49.3487 21.2962 49.1115 20.9641Z" fill="white" />
    <path className="flixbus-lettering" d="M47.8304 19.2085L46.9763 18.0697L45.9324 16.6462C45.6951 16.3615 45.3155 16.2666 44.9834 16.5039C44.6987 16.7411 44.6037 17.1207 44.841 17.4528L46.1222 19.2559L44.841 21.0589C44.6037 21.3436 44.6987 21.7707 44.9834 22.0079C45.2681 22.2452 45.6951 22.1503 45.9324 21.8656L46.6916 20.8217L47.8304 19.2085Z" fill="white" />
    <path className="flixbus-lettering" d="M48.9692 16.4565C48.6371 16.2192 48.2575 16.3142 48.0203 16.5988L47.3086 17.5953L48.1626 18.734L49.1115 17.4055C49.3487 17.1208 49.3013 16.6937 48.9692 16.4565Z" fill="white" />
    <path className="bumper-1" d="M97.4146 24.1434V24.2857V24.3332V24.3806C97.4146 24.3806 97.4146 24.3806 97.3671 24.3806C97.3197 24.3806 97.3671 24.3806 97.3196 24.3806H96.94V24.523H97.3671V24.6653V24.7128V24.7602C97.3671 24.7602 97.3671 24.7602 97.3196 24.7602C97.3196 24.7602 97.3196 24.7602 97.2722 24.7602H96.94V25.1873H96.7027L96.6553 24.1908V24.1434V24.0959C96.6553 24.0959 96.6553 24.0959 96.7027 24.0959C96.7027 24.0959 96.7027 24.0959 96.7502 24.0959L97.4146 24.1434Z" />
    <path className="bumper-1" d="M101.78 24.001V24.0959V24.1433C101.78 24.1433 101.78 24.1433 101.78 24.1908C101.78 24.1908 101.78 24.1908 101.732 24.1908C101.732 24.1908 101.732 24.1908 101.685 24.1908H101.4C101.4 24.1908 101.4 24.1908 101.353 24.1908C101.305 24.1908 101.353 24.1908 101.305 24.1908C101.305 24.1908 101.305 24.1908 101.305 24.2382V24.2856V24.3331C101.305 24.3331 101.305 24.3331 101.305 24.3805C101.305 24.3805 101.305 24.3805 101.353 24.3805C101.353 24.3805 101.353 24.3805 101.4 24.3805H101.495C101.542 24.3805 101.542 24.3806 101.59 24.428C101.637 24.428 101.637 24.4754 101.685 24.4754C101.732 24.5229 101.732 24.5229 101.732 24.5703C101.732 24.6178 101.732 24.6652 101.78 24.7127C101.78 24.7601 101.78 24.8076 101.78 24.855C101.78 24.9025 101.732 24.9499 101.732 24.9499C101.732 24.9974 101.685 24.9974 101.637 25.0448C101.59 25.0448 101.59 25.0923 101.542 25.0923H101.258C101.258 25.0923 101.258 25.0923 101.21 25.0923C101.21 25.0923 101.21 25.0923 101.163 25.0923C101.163 25.0923 101.163 25.0923 101.163 25.0448V24.9974V24.9025H101.542C101.542 24.9025 101.542 24.9025 101.59 24.9025C101.637 24.9025 101.59 24.9025 101.637 24.9025C101.637 24.9025 101.637 24.9025 101.637 24.855V24.8076V24.7601C101.637 24.7601 101.637 24.7601 101.637 24.7127C101.637 24.7127 101.637 24.7127 101.59 24.7127C101.59 24.7127 101.59 24.7127 101.542 24.7127H101.448C101.4 24.7127 101.4 24.7127 101.353 24.7127C101.305 24.7127 101.305 24.6652 101.258 24.6652C101.21 24.6178 101.21 24.6178 101.21 24.5703C101.21 24.5229 101.21 24.4755 101.163 24.428C101.163 24.3806 101.163 24.3331 101.163 24.2856C101.163 24.2382 101.21 24.1908 101.21 24.1908C101.21 24.1433 101.258 24.1433 101.305 24.0959C101.353 24.0484 101.353 24.0484 101.4 24.0484L101.78 24.001Z" />
    <path className="bumper-1" d="M101.068 24.0483L101.115 24.8075C101.115 24.855 101.115 24.9024 101.115 24.9499C101.115 24.9973 101.068 25.0448 101.068 25.0448C101.02 25.0922 101.02 25.0922 100.973 25.0922C100.925 25.0922 100.878 25.1397 100.831 25.1397H100.736C100.688 25.1397 100.641 25.1397 100.593 25.1397C100.546 25.1397 100.546 25.0922 100.498 25.0922C100.451 25.0448 100.451 25.0448 100.451 24.9973C100.451 24.9499 100.451 24.9024 100.404 24.855V24.1907V24.1432V24.0958C100.404 24.0958 100.404 24.0958 100.451 24.0958C100.451 24.0958 100.451 24.0958 100.498 24.0958H100.593V24.855V24.9024C100.593 24.9024 100.593 24.9499 100.641 24.9499C100.641 24.9499 100.688 24.9499 100.688 24.9973H100.736H100.783H100.831C100.831 24.9973 100.878 24.9973 100.878 24.9499C100.878 24.9024 100.878 24.9024 100.926 24.9024V24.855V24.2381V24.1907V24.1432C100.926 24.1432 100.925 24.1432 100.973 24.1432C100.973 24.1432 100.973 24.1432 101.02 24.1432L101.068 24.0483Z" />
    <path className="bumper-1" d="M99.2174 24.7126L99.3597 24.9499V24.9973V25.0448V25.0922C99.3597 25.0922 99.3597 25.1397 99.3123 25.1397C99.3123 25.1397 99.3123 25.1397 99.2649 25.1397C99.2174 25.1397 99.2649 25.1397 99.2649 25.1397L99.1226 24.9499L99.2174 24.7126Z" />
    <path className="bumper-1" d="M99.2648 24.0483C99.3122 24.0483 99.3122 24.0483 99.2648 24.0483C99.3122 24.0958 99.3122 24.0958 99.2648 24.0483C99.3122 24.0483 99.3122 24.0483 99.3122 24.0483C99.3122 24.0483 99.3122 24.0484 99.3597 24.0958C99.4071 24.1433 99.3597 24.1432 99.3597 24.1432V24.1907V24.2381L99.2173 24.5228L99.0751 24.2856L99.1699 24.0958C99.2173 24.0958 99.2174 24.0958 99.2648 24.0483C99.2174 24.0958 99.2174 24.0958 99.2648 24.0483Z" />
    <path className="bumper-1" d="M98.7904 24.0958C98.8379 24.0958 98.8379 24.0958 98.8379 24.0958L99.0277 24.3805L99.17 24.6177L98.9802 24.9024L98.8853 25.0922C98.8853 25.0922 98.8853 25.0922 98.8853 25.1397C98.8853 25.1397 98.8853 25.1397 98.8379 25.1397C98.8379 25.1397 98.8379 25.1397 98.7904 25.0922V25.0448V24.9973V24.9499L98.9802 24.5703L98.7429 24.2381V24.1907V24.1432V24.0958C98.7429 24.0958 98.743 24.0483 98.7904 24.0483C98.6955 24.0958 98.6955 24.0958 98.7904 24.0958C98.743 24.0958 98.743 24.0958 98.7904 24.0958C98.743 24.0958 98.743 24.0958 98.7904 24.0958C98.743 24.0958 98.743 24.0958 98.7904 24.0958Z" />
    <path className="bumper-1" d="M98.4108 24.0959C98.4108 24.0959 98.4583 24.0959 98.4108 24.0959C98.4583 24.0959 98.4583 24.0959 98.4583 24.1434C98.4583 24.1908 98.4583 24.1434 98.4583 24.1909V24.2383V24.3332V24.3806V24.4281C98.4583 24.4281 98.4583 24.4281 98.4108 24.4281C98.3633 24.4281 98.4108 24.4281 98.3634 24.4281H98.2209V24.2383V24.1909V24.1434C98.2209 24.1434 98.2209 24.1434 98.2684 24.1434C98.2684 24.1434 98.2684 24.1434 98.3159 24.1434L98.4108 24.0959Z" />
    <path className="bumper-1" d="M97.6516 24.1434C97.6991 24.1434 97.6991 24.1434 97.6516 24.1434C97.6991 24.1434 97.6991 24.1434 97.6991 24.1908C97.6991 24.2383 97.6991 24.1908 97.6991 24.2383C97.6991 24.2857 97.6991 24.2857 97.6991 24.2857V24.9975H98.0787V25.0924V25.1398V25.1873C98.0787 25.1873 98.0786 25.1873 98.0312 25.1873C98.0312 25.1873 98.0312 25.1873 97.9837 25.1873H97.5093H97.4618C97.4618 25.1873 97.4618 25.1873 97.4144 25.1873C97.4144 25.1873 97.4144 25.1873 97.4144 25.1398V25.0924L97.3669 24.0959L97.6516 24.1434Z" />
    <path className="bumper-1" d="M100.214 24.6179C100.261 24.6653 100.261 24.7128 100.261 24.7128V24.7602V24.8077C100.261 24.8551 100.261 24.9026 100.261 24.95C100.261 24.9975 100.214 25.0449 100.214 25.0449C100.214 25.0924 100.166 25.0924 100.119 25.0924C100.072 25.0924 100.072 25.0924 100.024 25.1398H99.6445C99.6445 25.1398 99.6445 25.1398 99.5971 25.1398C99.5496 25.1398 99.5971 25.1398 99.5496 25.1398C99.5496 25.1398 99.5496 25.1398 99.5496 25.0924V25.0449L99.5022 24.1909V24.1434C99.5022 24.1434 99.5022 24.1434 99.5022 24.0959C99.5022 24.0959 99.5022 24.0959 99.5496 24.0959C99.5496 24.0959 99.5496 24.0959 99.5971 24.0959H99.9292C99.9766 24.0959 100.024 24.0959 100.024 24.0959C100.072 24.0959 100.072 24.1434 100.119 24.1434C100.119 24.1908 100.166 24.1908 100.166 24.2383C100.166 24.2857 100.166 24.3332 100.166 24.3806V24.4281V24.4755V24.523C100.166 24.523 100.214 24.5704 100.214 24.6179ZM99.9767 24.95C99.9767 24.95 100.024 24.95 99.9767 24.95C100.024 24.95 100.024 24.9026 100.072 24.9026V24.8551V24.8077V24.7602C100.072 24.7602 100.072 24.7128 100.024 24.7128C100.024 24.7128 100.024 24.7128 99.9767 24.6653H99.9292H99.6919V24.8551V24.9026C99.6919 24.9026 99.6919 24.9026 99.6919 24.95C99.6919 24.95 99.692 24.95 99.7394 24.95C99.7394 24.95 99.7394 24.95 99.7869 24.95H99.9767ZM99.7394 24.2383V24.3806V24.4281C99.7394 24.4281 99.7394 24.4281 99.7394 24.4755C99.7394 24.4755 99.7394 24.4755 99.7869 24.4755H99.9292H99.9767C99.9767 24.4755 99.9767 24.4755 100.024 24.4281V24.3806V24.3332V24.2857V24.2383C100.024 24.2383 100.024 24.2383 99.9767 24.1909H99.9292L99.7394 24.2383Z" />
    <path className="bumper-1" d="M98.5057 24.5229V25.1872H98.2686V24.6178V24.5704V24.5229C98.2686 24.5229 98.2686 24.5229 98.316 24.5229C98.316 24.5229 98.316 24.5229 98.3634 24.5229H98.5057Z" />
    <path className="dark-blue-color" d="M80.9496 4.03442H79.8109V14.6155H80.9496V4.03442Z" />
    <path className="dark-blue-color" d="M11.1053 14.6061C11.1053 14.6061 2.23242 14.4637 2.23242 23.6688H1.28345L1.37832 14.6061H11.1053Z" />
    <path className="dark-blue-color" d="M3.41876 14.0367L1.33081 12.8979V15.128H3.41876V14.0367Z" />
    <path className="red-lights" d="M1.28337 20.4421C1.28337 20.4421 1.141 24.1431 1.28337 26.6105C1.28337 26.6105 2.18497 26.2783 2.18497 25.8038C2.18497 25.8038 2.42229 20.7743 1.28337 20.4421Z" />
    <path className="medium-green-color" d="M83.6068 21.6758H82.6578C82.3732 21.6758 82.1359 21.913 82.1359 22.1977C82.1359 22.4824 82.3732 22.7197 82.6578 22.7197H83.6068C83.8915 22.7197 84.1287 22.4824 84.1287 22.1977C84.1287 21.8656 83.8915 21.6758 83.6068 21.6758Z" />
    <path className="light-green-color" d="M82.563 22.0554C82.563 21.913 82.6579 21.8181 82.8002 21.8181H83.6069C83.7492 21.8181 83.8441 21.913 83.8441 22.0554C83.8441 22.1977 83.7492 22.2926 83.6069 22.2926H82.8002C82.6579 22.2926 82.563 22.1977 82.563 22.0554Z" />
    <path className="dark-blue-color" d="M80.997 19.0187C80.997 19.0187 71.8394 14.606 64.5323 14.606C61.6854 14.606 80.3802 14.0366 80.3802 14.0366L80.997 19.0187Z" />
  </svg>
);

export default Bus;
