import { Heading, Tag } from "@flixbus/honeycomb-react";

const Lines = ({ lines, removeItemAtIndex, copyAll, handleClearAll }) => {
    return (
        <>
            {lines?.length > 0 &&
                <Heading extraClasses='flix-space-2-top' key={1} size={2}>Search for PAX Lists</Heading>
            }

            {
                lines?.map((element, index) => (
                    <>
                        <Tag tagColor="white" closeProps={{ label: 'Close', onClick: (e) => removeItemAtIndex(index) }} extraClasses='flix-space-1-top flix-space-1-right border-1'>
                            {element}
                        </Tag>
                        {index === lines.length - 1 && (
                            <Tag extraClasses='flix-space-1-top flix-space-1-right primary' Elem="button" type="button" onClick={copyAll}>Copy All</Tag>
                        )}
                        {index === lines.length - 1 && (
                            <Tag closeProps={{ label: 'Close', onClick: () => handleClearAll() }} extraClasses='flix-space-1-top secondary'>Reset All</Tag>
                        )}
                    </>
                ))
            }
        </>
    );
}

export default Lines;