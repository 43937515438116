import { useMsal } from '@azure/msal-react';
import {
    Header,
    HeaderBrand,
    HeaderNavigation,
    HeaderSubNavigation,
    HeaderUserWidget,
    HeaderWidgets,
    NavItem,
} from '@flixbus/honeycomb-react';
import { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './AppHeader.module.scss';
import { loginRequest } from '../../configs/authConfig';

const handleLogOut = (instance) => {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

const handleLogin = (instance) => {
    localStorage.clear();
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

const AppHeader = () => {
    const { accounts, instance } = useMsal();
    const account = accounts[0];
    const location = useLocation();

    // const isActive = (href) => location.pathname === href;

    const isActive = (href) => {
        if (href === '/') {
            return location.pathname === href;
        }
        return location.pathname.startsWith(href);
    };

    return (
        <Header fixed>
            <HeaderBrand
                key="header-brand"
                alt="Flixbus"
                href="/"
                src={"https://honeycomb.flixbus.com/dist/7.2.13/img/logos/svg/flixbus-white.svg"}
                RouterLink={NavLink}
            />
            {account ?
                <HeaderNavigation aria-label="Main">
                    <NavItem href='/' active={isActive('/')}>
                        Search
                    </NavItem>
                    <NavItem href='/requests' active={isActive('/requests')}>
                        Requests
                    </NavItem>
                </HeaderNavigation>
                : ""
            }
            <HeaderWidgets>
                {account ?
                    <HeaderUserWidget
                        data-dd-privacy="mask"
                        subMenu={
                            <HeaderSubNavigation id="pikachu-sub-menu" data-dd-privacy="allow">
                                <NavItem href="#" onClick={() => handleLogOut(instance)}>Logout</NavItem>
                            </HeaderSubNavigation>
                        }
                    >
                        {account.name || ''}
                    </HeaderUserWidget>
                    :
                    <HeaderUserWidget onClick={() => handleLogin(instance)}>
                        Login
                    </HeaderUserWidget>
                }
            </HeaderWidgets>
        </Header>
    );
};

export default memo(AppHeader);
