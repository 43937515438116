import React, { useEffect } from "react";
import StateContext from "./contexts/StateContext";
import DispatchContext from "./contexts/DispatchContext";
import {
  BrowserRouter,
  Link,
  Route,
  Switch as RouterSwitch,
} from "react-router-dom";
import {
  Header,
  HeaderUserWidget,
  HeaderBrand,
  HeaderWidgets,
  MainWrapper,
  PageContainer,
  ThemeWrapper,
} from '@flixbus/honeycomb-react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { loginRequest } from "./configs/authConfig";
import { createBrowserHistory } from "history";
// Honeycomb-react helper classes
import '@flixbus/honeycomb-react/dist/css/honeycomb-helpers.css';
// imports Roboto font face and styles
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
import './App.css';
import PlatformFooter from "./components/platform-footer/platform-footer";
import Search from "./pages/Search/Search";
import Requests from "./pages/Requests/Requests";
import RequestDetail from "./pages/Requests/detail/RequestDetail";
import AppHeader from "./components/header/AppHeader";
import { useImmerReducer } from "use-immer";
import { combinedReducer } from "./reducers/combinedReducer";
import Loader from "./components/loader/Loader";
import LoginMessage from "./components/login-message/LoginMessage";

function App() {
  const initialState = {
    loggedIn: Boolean(localStorage.getItem("token")),
    token: "",
    isLoading: false,
  };

  const [state, dispatch] = useImmerReducer(combinedReducer, initialState);

  const isAuthenticated = useIsAuthenticated();

  const { instance, accounts } = useMsal();

  useEffect(() => {
    const effectData = async () => {
      if (isAuthenticated) {
        const request = {
          ...loginRequest,
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(request)
          .then((response) => {
            dispatch({ type: "login", token: response.accessToken });
            localStorage.setItem("token", response.accessToken);
          })
          .catch((e) => {
            instance.loginRedirect(loginRequest).catch((e) => {
              console.error(e);
            });
            console.log(e);
          });
      }
    }
    effectData();
  }, [instance, accounts, isAuthenticated]);

  const history = createBrowserHistory();

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <ThemeWrapper theme="default" >
          <MainWrapper full>
            <BrowserRouter history={history}>
              <AppHeader />
              <PageContainer Elem="main" hasFixedHeader>
                <AuthenticatedTemplate>
                  <Loader />
                  <RouterSwitch>
                    <Route exact path="/">
                      <Search />
                    </Route>
                    <Route exact path="/requests">
                      <Requests />
                    </Route>
                    <Route exact path="/requests/detail">
                      <RequestDetail />
                    </Route>
                  </RouterSwitch>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <LoginMessage />
                  <PlatformFooter />
                </UnauthenticatedTemplate>
              </PageContainer>
            </BrowserRouter>
          </MainWrapper>
        </ThemeWrapper>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default App;
