/* eslint-disable arrow-body-style */
import React from 'react';
import Landscape from './landscape/landscape';
import './platform-footer.scss';

const PlatformFooter = () => {
  return (
    <div className="platform-footer">
      <Landscape />
    </div>
  );
};

export default PlatformFooter;
